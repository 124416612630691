import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { transport } from "../utils/TransportApi";

const MySwal = withReactContent(Swal);

// Define the Partners component
function Partners() {
  const [partners, setPartners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showFormAddPartner, setShowFormAddPartner] = useState(false);

  const [editFormData, setEditFormData] = useState({
    id: "",
    partner_name: "",
    raison_social: "",
    phone_number: "",
    address: "",
    logo: null,
  });

  const [showFormEditPartner, setShowFormEditPartner] = useState(false);

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Add partners handlers
  const toggleForm = () => {
    setShowFormAddPartner(!showFormAddPartner);
  };

  const [formData, setFormData] = useState({
    partner_name: "",
    raison_social: "",
    phone_number: "",
    address: "",
    logo: null,
  });

  // Function to handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await axios.post(
        "https://api.essenlux.ma/api/add",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.partner) {
        const newPartner = response.data.partner;

        // Reset the form data
        setFormData({
          partner_name: "",
          raison_social: "",
          phone_number: "",
          address: "",
          logo: null,
        });

        // Update the partners state with the new partner
        setPartners((prevPartners) => [...prevPartners, newPartner]);

        // Display success message using SweetAlert
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "New partner added successfully!",
          confirmButtonColor: "#4CAF50",
        });

        setShowFormAddPartner(false);
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle unexpected response format
      }
    } catch (error) {
      console.error("Error while adding partner:", error);
      // Display error message or perform error handling
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle file input change
  const handleFileChangeAdd = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      logo: file,
    });
  };

  // Edit partner handlers
  const openEditForm = (partner) => {
    setEditFormData({
      id: partner.id,
      partner_name: partner.partner_name,
      raison_social: partner.raison_social,
      phone_number: partner.phone_number,
      address: partner.address,
      logo: null,
    });
    setShowFormEditPartner(true);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEditFormData((prevData) => ({
      ...prevData,
      logo: file,
    }));
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(editFormData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await axios.post(
        `https://api.essenlux.ma/api/update/${editFormData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(response.data.message);
      setShowFormEditPartner(false);
      setPartners(response.data.partners.data);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.error("Validation Error:", error.response.data.errors);
      } else {
        console.error("Error while updating partner:", error);
      }
    }
  };
  // edit partner ends here

  //delete partner starts here
  const handleDelete = (id) => {
    MySwal.fire({
      html:
        '<div class="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg ">' +
        '<div class="py-2 text-center">' +
        '<p class="text-sm text-gray-600  ml-16">Voulez-vous vraiment supprimer ce partenaire ?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false, // Disable default styles to ensure custom classes are applied
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://api.essenlux.ma/api/partners/${id}`)
          .then((response) => {
            setPartners((prevPartners) =>
              prevPartners.filter((partner) => partner.id !== id)
            );
            // toast.success(response.data.message);
            Swal.fire({
              text: `Partenaire supprimé avec succès`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
              },
            });
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    });
  };
  // delete partner ends here

  useEffect(() => {
    const fetchPartners = async (page) => {
      try {
        const response = await transport.get(
          `https://api.essenlux.ma/api/getAllPartners?page=${page}`
        );
        setPartners(response.data.partners.data); // Assuming the partners array is inside `data`
        setTotalPages(response.data.partners.last_page);
      } catch (error) {
        console.error("Failed to fetch partners:", error);
      }
    };

    fetchPartners(currentPage);
  }, [currentPage]);

  return (
    <div className="content">
      <div className="w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-mb-2 py-4 flex flex-wrap flex-grow justify-between">
              <div className="flex items-center py-2">
                <button
                  className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleForm}
                >
                  {showFormAddPartner ? "Close Form" : "Nouveau Partenaire"}
                </button>
                {showFormAddPartner && (
                  <div
                    id="authentication-modal"
                    tabIndex="-1"
                    aria-labelledby="modal-title"
                    aria-hidden="true"
                    className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
                  >
                    <div className="relative p-4 w-[50%]">
                      <div className="relative bg-white rounded-lg shadow ">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                          <h3
                            id="modal-title"
                            className="text-2xl font-bold text-[#CBA660] "
                          >
                            Ajouter nouveau partenaire
                          </h3>
                          <button
                            type="button"
                            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={toggleForm}
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                        </div>
                        <div className="p-4 md:p-5">
                          <form
                            className="space-y-4"
                            onSubmit={handleFormSubmit}
                            encType="multipart/form-data"
                          >
                            <div>
                              <label
                                htmlFor="logo"
                                className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                              >
                                Logo
                              </label>
                              <input
                                type="file"
                                id="logo"
                                name="logo"
                                onChange={handleFileChangeAdd}
                                accept="image/*"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="partner_name"
                                className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                              >
                                Nom et Prénom
                              </label>
                              <input
                                type="text"
                                id="partner_name"
                                name="partner_name"
                                value={formData.partner_name}
                                onChange={handleInputChange}
                                placeholder="Nom et Prénom"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="raison_social"
                                className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                              >
                                Raison social
                              </label>
                              <input
                                type="text"
                                id="raison_social"
                                name="raison_social"
                                value={formData.raison_social}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Raison social"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="phone_number"
                                className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                              >
                                Téléphone
                              </label>
                              <input
                                type="tel"
                                id="phone_number"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Téléphone"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="address"
                                className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                              >
                                Addresse
                              </label>
                              <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Addresse"
                              />
                            </div>
                            <button
                              type="submit"
                              className="w-auto md:mx-1 bg-[#CBA660] text-white hover:bg-[#22213D] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  {/* <!-- HEAD start --> */}
                  <thead>
                    <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                      <th className="px-6 py-5 text-left font-bold">logo</th>
                      <th className="px-6 py-5 text-left font-bold">
                        nom de partenaire
                      </th>
                      <th className="px-6 py-5 text-left font-bold">
                        raison social
                      </th>
                      <th className="px-6 py-5 text-left font-bold">
                        téléphone
                      </th>
                      <th className="px-6 py-5 text-left font-bold">
                        addresse
                      </th>
                      <th className="px-6 py-5 text-left font-bold">action</th>
                    </tr>
                  </thead>
                  {/* <!-- HEAD end --> */}
                  {/* <!-- BODY start --> */}
                  <tbody className="bg-gray-50 w-full">
                    {partners.length > 0 ? (
                      partners.map((partner) => (
                        <tr key={partner.id}>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                {partner && partner.logo ? (
                                  <img
                                    src={`https://api.essenlux.ma/storage/${partner.logo}`}
                                    alt="Partner Logo"
                                    className="h-13 w-13 rounded-full object-cover"
                                    style={{ width: "100px", height: "43px" }}
                                  />
                                ) : (
                                  <div className="flex-shrink-0">
                                    <h1 className="p-2 rounded-full bg-[#DDE6ED] uppercase font-bold text-gray-500 flex justify-center items-center">
                                      {partner
                                        ? partner.partner_name.substring(0, 2)
                                        : ""}
                                    </h1>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {partner.partner_name}
                            </div>
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {partner.raison_social === "null"
                                ? null
                                : partner.raison_social}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            {partner.phone_number === "null"
                              ? null
                              : partner.phone_number}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            {partner.address === "null"
                              ? null
                              : partner.address}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 capitalize gap-3">
                            <button
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-red-100 text-red-800"
                              onClick={() => handleDelete(partner.id)}
                            >
                              <svg
                                className="w-4 h-4 text-red-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <button
                              className="p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800"
                              onClick={() => openEditForm(partner)}
                            >
                              <svg
                                className="w-4 h-4 text-green-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div className="mx-auto my-10 max-w-2xl text-center sm:my-24">
                            <h2 className="mt-4 text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                              Aucune donnée disponible pour le moment{" "}
                            </h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {showFormEditPartner && (
                    <div
                      id="authentication-modal"
                      tabIndex="-1"
                      aria-hidden="true"
                      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
                    >
                      <div className="relative p-4 w-full max-w-md">
                        <div className="relative bg-white rounded-lg shadow ">
                          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900  capitalize">
                              Modifier les données de partenaire
                            </h3>
                            <button
                              type="button"
                              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                              onClick={() => setShowFormEditPartner(false)}
                            >
                              <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                              </svg>
                              <span className="sr-only">Close modal</span>
                            </button>
                          </div>
                          <div className="p-4 md:p-5">
                            <form
                              onSubmit={handleEditFormSubmit}
                              className="space-y-4"
                            >
                              <div>
                                <label
                                  htmlFor="logo"
                                  className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                                >
                                  Logo
                                </label>
                                <input
                                  type="file"
                                  name="logo"
                                  id="logo"
                                  onChange={handleFileChange}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="Nom et Prénom"
                                  className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                                >
                                  Nom et Prénom
                                </label>
                                <input
                                  type="text"
                                  name="partner_name"
                                  id="partner_name"
                                  value={editFormData.partner_name}
                                  onChange={handleEditInputChange}
                                  placeholder="Nom et Prénom"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="Raison Social"
                                  className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                                >
                                  Raison Social
                                </label>
                                <input
                                  type="text"
                                  name="raison_social"
                                  id="raison_social"
                                  value={editFormData.raison_social}
                                  onChange={handleEditInputChange}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                  placeholder="Raison Social"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="Téléphone"
                                  className="block mb-2 text-sm font-medium text-[#22213D] capitalize"
                                >
                                  Téléphone
                                </label>
                                <input
                                  type="tel"
                                  name="phone_number"
                                  id="phone_number"
                                  value={editFormData.phone_number}
                                  onChange={handleEditInputChange}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                  placeholder="Téléphone"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="addresse"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Addresse
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  value={editFormData.address}
                                  onChange={handleEditInputChange}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                  placeholder="Addresse"
                                />
                              </div>
                              <button
                                type="submit"
                                className="w-auto md:mx-1 bg-[#CBA660] text-white hover:bg-[#22213D] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </table>
              </div>
              <div className="flex justify-end items-center p-4">
                {/* Bouton précédent */}
                {currentPage > 1 && (
                  <button
                    onClick={handlePrevPage}
                    className="p-2 rounded-md text-blue-700 relative"
                  >
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 12h14M5 12l4-4m-4 4 4 4"
                      />
                    </svg>
                  </button>
                )}

                {/* Affichage de la page actuelle et le nombre total de pages */}
                <p className="text-gray-500 text-sm px-2">
                  Page {currentPage} of {totalPages}
                </p>

                {/* Bouton suivant */}
                {currentPage < totalPages && (
                  <button
                    onClick={handleNextPage}
                    className="p-2 rounded-md text-blue-700 relative"
                  >
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
