import React, { useState, useEffect } from "react";
import { transport } from "../utils/TransportApi";
import { useForm } from "react-hook-form";
import ProductCards from "./ProductCards";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import { features, interiors, options } from "../utils/helpers";

const Products = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [subTypes, setSubTypes] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();
  const [error, setError] = useState("");
  const [productTypes, setProductTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedInterior, setSelectedInterior] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  // ******************************************************
  const uploadImages = async (files) => {
    const batchSize = 10;
    const formDataBatches = [];
    const filesArray = Array.from(files);

    for (let i = 0; i < filesArray.length; i += batchSize) {
      const batch = filesArray.slice(i, i + batchSize);
      const formData = new FormData();
      batch.forEach((file) => formData.append("images[]", file));
      formDataBatches.push(formData);
    }

    setIsLoading(true);
    setUploadSuccess(false);
    try {
      const paths = [];
      for (const formData of formDataBatches) {
        const response = await transport.post("/uploadImages", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        paths.push(...response.data.paths);
      }
      setUploadSuccess(true);
      return paths;
    } catch (error) {
      toast.error("Failed to upload images. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const imageHandler = async (e) => {
    let { name, files } = e.target;
    try {
      const imagePaths = await uploadImages(files);

      if (
        Array.isArray(imagePaths) &&
        imagePaths.every((path) => typeof path === "string")
      ) {
        setValue(name, imagePaths);
      } else {
        toast.error("Failed to process image paths. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      data.main_features = selectedFeatures;
      data.interior = selectedInterior;
      data.additional_options = selectedOptions;

      await transport
        .post("/addProduct", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((res) => {
          const newProduct = res.data;
          setProducts((prevProducts) => [...prevProducts, newProduct]);
        })
        .finally(() => toast.success("Product added successfully!"));

      setValue("name", "");
      setValue("description", "");
      setValue("address", "");
      setValue("lien_video", "");
      setValue("price", "");
      setValue("rooms_number", "");
      setValue("status", "");
      setValue("ville", "");
      setValue("surface", "");
      setValue("product_type_id", "");
      setValue("sub_type_id", "");
      setValue("douche", "");
      setValue("toillete", "");
      setValue("images", []);
      reset();
      setSelectedOptions([]);
      setSelectedInterior([]);
      setSelectedFeatures([]);

      toggleForm();
      window.location.reload();
    } catch (e) {
      toast.error(
        "Une erreur s'est produite lors de la création du produit. Veuillez réessayer."
      );
      setError(e.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    transport
      .get("/getAllTypes")
      .then((response) => {
        setProductTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
      });

    transport
      .get(`/products?page=${currentPage}`)
      .then((response) => {
        setProducts(response.data.products.data);
        setTotalPages(response.data.products.last_page);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, [currentPage]);

  // Gestion du changement de type sélectionné
  const handleTypeChange = (event) => {
    const typeId = event.target.value;
    setSelectedType(typeId);
  };
  useEffect(() => {
    // Update subtypes when the selectedType changes
    if (selectedType) {
      const selectedTypeData = productTypes.find(
        (type) => type.id === parseInt(selectedType)
      );
      if (selectedTypeData && selectedTypeData.sub_types) {
        setSubTypes(selectedTypeData.sub_types);
      } else {
        setSubTypes([]);
      }
    }
  }, [selectedType, productTypes]);

  const selectFeature = (featureId) => {
    setSelectedFeatures((prevSelectedFeatures) =>
      prevSelectedFeatures.includes(featureId)
        ? prevSelectedFeatures.filter((id) => id !== featureId)
        : [...prevSelectedFeatures, featureId]
    );
  };

  const selectInterior = (interiorId) => {
    setSelectedInterior((prevSelectedInterior) =>
      prevSelectedInterior.includes(interiorId)
        ? prevSelectedInterior.filter((id) => id !== interiorId)
        : [...prevSelectedInterior, interiorId]
    );
  };

  const selectOption = (optionId) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(optionId)
        ? prevSelectedOptions.filter((id) => id !== optionId)
        : [...prevSelectedOptions, optionId]
    );
  };

  return (
    <div className="content">
      <div className="w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-mb-2 py-4 flex flex-wrap flex-grow justify-between">
              <div className="flex items-center py-2">
                <button
                  className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleForm}
                >
                  {showForm ? "Close Form" : "Nouveau produit"}
                </button>
                {showForm && (
                  <div
                    id="authentication-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
                  >
                    <div className="relative p-4 w-[50%] max-w-3xl">
                      <div className="relative bg-white  rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                          <h3 className="text-xl font-semibold text-[#CBA660]">
                            Nouveau produit
                          </h3>
                          <button
                            type="button"
                            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={toggleForm}
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                        </div>
                        <div className="p-4 md:p-5 overflow-y-auto max-h-[80vh]">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-4 md:p-5 grid grid-cols-2 gap-4">
                              {/* Première colonne */}
                              <div>
                                <label
                                  htmlFor="Nom de produit"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Nom de produit
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  {...register("name")}
                                  placeholder="Nom de produit"
                                  required
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="surface"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  surface
                                </label>
                                <input
                                  type="number"
                                  name="surface"
                                  id="surface"
                                  {...register("surface")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="surface"
                                  required
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="Prix"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Prix
                                </label>
                                <input
                                  type="number"
                                  name=""
                                  id="price"
                                  {...register("price")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="Prix"
                                  required
                                />
                              </div>
                              {/* Deuxième colonne */}
                              <div>
                                <label
                                  htmlFor="images"
                                  className="text-sm font-medium text-gray-900 "
                                >
                                  images
                                </label>
                                <input
                                  type="file"
                                  name="images"
                                  id="images"
                                  accept="image/png, image/jpg, image/jpeg, image/webp"
                                  onInput={imageHandler}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                  placeholder="Images"
                                  multiple
                                  required
                                />
                                {isLoading && (
                                  <p className="text-blue-600">
                                    Téléchargement d'images...
                                  </p>
                                )}
                                {uploadSuccess && (
                                  <p className="text-green-600">
                                    Images téléchargées avec succès !
                                  </p>
                                )}
                              </div>
                              <div>
                                <label
                                  htmlFor="lien_video"
                                  className="text-sm font-medium text-gray-900 "
                                >
                                  lien vidéo
                                </label>
                                <input
                                  type="text"
                                  name="lien_video"
                                  id="lien_video"
                                  {...register("lien_video")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                  placeholder="entrer le lien içi..."
                                  multiple
                                  required
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="douche"
                                  className="text-sm font-medium text-gray-900 "
                                >
                                  Salon
                                </label>
                                <input
                                  type="number"
                                  name="douche"
                                  id="douche"
                                  {...register("douche")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                  placeholder="entrer ici"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="toillete"
                                  className="text-sm font-medium text-gray-900 "
                                >
                                  salle de bain
                                </label>
                                <input
                                  type="number"
                                  name="toillete"
                                  id="toillete"
                                  {...register("toillete")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                  placeholder="entrer ici"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="etat"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Type
                                </label>
                                <select
                                  name="product_type_id"
                                  id="type"
                                  className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  value={selectedType}
                                  {...register("product_type_id")}
                                  onChange={handleTypeChange}
                                  required
                                >
                                  <option value="" disabled selected>
                                    Sélectionner type de produit
                                  </option>
                                  {productTypes?.map((type) => (
                                    <option
                                      key={type.id}
                                      value={type.id}
                                      onChange={(e) =>
                                        setSubTypes(e.target.value)
                                      }
                                    >
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {subTypes.length > 0 && (
                                <div>
                                  <label
                                    htmlFor="subType"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                  >
                                    Sous-type de produit
                                  </label>
                                  <select
                                    id="subType"
                                    name="sub_type_id"
                                    {...register("sub_type_id")}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                    required
                                  >
                                    <option value="">Select sub-type</option>
                                    {subTypes.map((subType) => (
                                      <option
                                        key={subType.id}
                                        value={subType.id}
                                      >
                                        {subType.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              <div>
                                <label
                                  htmlFor="status"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  status
                                </label>
                                <select
                                  name="status"
                                  id="status"
                                  {...register("status")}
                                  defaultValue="disponible" // Modifier la valeur par défaut ici
                                  className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  required
                                >
                                  <option value="" disabled hidden>
                                    status
                                  </option>
                                  <option value="vendu">vendu</option>
                                  <option value="loué">loué</option>
                                  <option value="disponible">
                                    disponible
                                  </option>{" "}
                                  {/* Ajouter "disponible" comme option par défaut */}
                                </select>
                              </div>
                              <div>
                                <label
                                  htmlFor="ville"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  ville
                                </label>
                                <input
                                  type="text"
                                  name="ville"
                                  id="ville"
                                  {...register("ville")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="ville"
                                  required
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="address"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  address
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  {...register("address")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="address"
                                  required
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="rooms_number"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Nombre de chambres
                                </label>
                                <input
                                  type="number"
                                  name="rooms_number"
                                  id="rooms_number"
                                  {...register("rooms_number")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="nombre de chambres"
                                />
                              </div>{" "}
                              <div className="col-span-2">
                                <label
                                  htmlFor="description"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  description
                                </label>
                                <textarea
                                  type="text"
                                  name="description"
                                  id="description"
                                  {...register("description")}
                                  required
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="description"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Étage du bien
                                </label>
                                <input
                                  type="number"
                                  name="floor"
                                  id="floor"
                                  {...register("floor")}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                  placeholder="Étage"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="soil_type"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Type du sol
                                </label>
                                <select
                                  name="soil_type"
                                  id="soil_type"
                                  {...register("soil_type")}
                                  className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                >
                                  <option value="">Sélectionnez</option>
                                  <option value="parquet">Parquet</option>
                                  <option value="marbre">Marbre</option>
                                  <option value="carrelage">Carrelage</option>
                                  <option value="autre">Autre</option>
                                </select>
                              </div>
                              <div>
                                <label
                                  htmlFor="product_etat"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Etat du bien
                                </label>
                                <select
                                  name="product_etat"
                                  id="product_etat"
                                  {...register("product_etat")}
                                  className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                >
                                  <option value="">Sélectionnez</option>
                                  <option value="moins d'un an">
                                    Moins d'un an
                                  </option>
                                  <option value="d'un an à 5 ans">
                                    D'un an à 5 ans
                                  </option>
                                  <option value="de 5 ans à 10 ans">
                                    De 5 ans à 10 ans
                                  </option>
                                  <option value="de 10 ans à 20 ans">
                                    De 10 ans à 20 ans
                                  </option>
                                  <option value="de 20 ans à 30 ans">
                                    De 20 ans à 30 ans
                                  </option>
                                  <option value="de 30 ans à 50 ans">
                                    De 30 ans à 50 ans
                                  </option>
                                  <option value="de 50 ans à 70 ans">
                                    De 50 ans à 70 ans
                                  </option>
                                  <option value="de 70 ans à 100 ans">
                                    De 70 ans à 100 ans
                                  </option>
                                  <option value="plus de 100 ans">
                                    Plus de 100 ans
                                  </option>
                                </select>
                              </div>
                              <div>
                                <label
                                  htmlFor="orientation"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Orientation
                                </label>
                                <select
                                  name="orientation"
                                  id="orientation"
                                  {...register("orientation")}
                                  className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                >
                                  <option value="">Sélectionnez</option>
                                  <option value="nord">Nord</option>
                                  <option value="ouest">Ouest</option>
                                  <option value="est">Est</option>
                                  <option value="sud">Sud</option>
                                </select>
                              </div>
                              <div className="col-span-2 mt-4">
                                <label
                                  htmlFor="main_features"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Caractéristiques générales:
                                </label>
                                <div className="mt-2 flex flex-row gap-6 flex-wrap justify-start items-start">
                                  {features.map((feature) => (
                                    <div key={feature.id} className="">
                                      <a
                                        id={`${feature.id}Link`}
                                        onClick={() =>
                                          selectFeature(feature.id)
                                        }
                                        className={`cursor-pointer items-center flex flex-col justify-center p-1 ${
                                          selectedFeatures.includes(feature.id)
                                            ? "text-[#CBA660]"
                                            : "text-gray-500"
                                        }`}
                                      >
                                        <div className="characIcon">
                                          {feature.icon}
                                        </div>
                                        <span className="characIconText centered">
                                          {feature.label}
                                        </span>
                                      </a>
                                      <input
                                        id={feature.id}
                                        type="hidden"
                                        name={feature.id}
                                        value={selectedFeatures.includes(
                                          feature.id
                                        )}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="col-span-2 mt-4">
                                <label
                                  htmlFor="interior"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Intérieur:
                                </label>
                                <div className="mt-2 flex flex-row gap-6 flex-wrap justify-start items-start">
                                  {interiors.map((interior) => (
                                    <div
                                      key={interior.id}
                                      className="characLinkBox col-2 centered floatR"
                                    >
                                      <a
                                        id={`${interior.id}Link`}
                                        onClick={() =>
                                          selectInterior(interior.id)
                                        }
                                        className={`cursor-pointer items-center flex flex-col justify-center p-1 ${
                                          selectedInterior.includes(interior.id)
                                            ? "text-[#CBA660]"
                                            : "text-gray-500"
                                        }`}
                                      >
                                        <div className="characIcon">
                                          {interior.icon}
                                        </div>
                                        <span className="characIconText centered">
                                          {interior.label}
                                        </span>
                                      </a>
                                      <input
                                        id={interior.id}
                                        type="hidden"
                                        name={interior.id}
                                        value={selectedInterior.includes(
                                          interior.id
                                        )}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="col-span-2 mt-4">
                                <label
                                  htmlFor="additional_options"
                                  className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Options supplémentaires:
                                </label>
                                <div className="mt-2 flex flex-row gap-6 flex-wrap justify-start items-start">
                                  {options.map((feature) => (
                                    <div
                                      key={feature.id}
                                      className="characLinkBox col-2 centered floatR"
                                    >
                                      <a
                                        id={`${feature.id}Link`}
                                        onClick={() =>
                                          selectOption(feature.id, "options")
                                        }
                                        className={`cursor-pointer items-center flex flex-col justify-center p-1 ${
                                          selectedOptions.includes(feature.id)
                                            ? "text-[#CBA660]"
                                            : "text-gray-500"
                                        }`}
                                      >
                                        <div className="characIcon">
                                          {feature.icon}
                                        </div>
                                        <span className="characIconText centered">
                                          {feature.label}
                                        </span>
                                      </a>
                                      <input
                                        id={feature.id}
                                        type="hidden"
                                        name={feature.id}
                                        value={selectedOptions.includes(
                                          feature.id
                                        )}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {/* Add more form fields as needed */}
                            <button
                              type="submit"
                              className="w-auto md:mx-5 mb-4 bg-[#CBA660] text-white hover:bg-[#22213D] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                              Submit
                            </button>
                            {loading && (
                              <div className="absolute left-1/2 top-0 flex justify-center items-center z-50">
                                <Loader />
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <ProductCards
              products={products}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
