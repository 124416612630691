import React, { useContext, useEffect, useState } from "react";

// Step 1: Create AuthContext
const AuthContext = React.createContext();

// Step 2: Create custom hook for authentication
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// Step 3: AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if user is logged in from local storage
    const loggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
    const storedToken = sessionStorage.getItem('tokenApi');

    if (loggedIn && storedToken) {
      setIsLoggedIn(true);
      setToken(storedToken); // Set token state
    }
    setLoading(false);
  }, []);

  const login = (token) => {
    setIsLoggedIn(true);
    setToken(token);
    sessionStorage.setItem('tokenApi', token);
    sessionStorage.setItem('isLoggedIn', 'true');
  };

  const logout = async () => {
    setIsLoggedIn(false);
    setToken(null);
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('tokenApi');
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, loading, token }}
    >
      {children}
    </AuthContext.Provider>
  );
};
