import React, { useEffect, useState } from "react";
import { transport } from "../../utils/TransportApi";
import AddTestimonialModal from "./AddTestimonialModal";
import UpdateTestimonialModal from "./UpdateTestimonialModal";
import "../../styles/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [showFormAddTestimonial, setShowFormAddTestimonial] = useState(false);
  const [showFormEditTestimonial, setShowFormEditTestimonial] = useState(false);
  const [editTestimonial, setEditTestimonial] = useState(null);

  const toggleForm = () => {
    setShowFormAddTestimonial(!showFormAddTestimonial);
  };

  const toggleFormEdit = () => {
    setShowFormEditTestimonial(!showFormEditTestimonial);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await transport.get("/testimonials");
      setTestimonials(response.data);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleDelete = (id) => {
    transport
      .delete(`testimonials/${id}`)
      .then((response) => {
        if (response && response.data) {
          toast.success("testimonial deleted successfully!");
          setTestimonials(
            testimonials.filter((testimonial) => testimonial.id !== id)
          );
        } else {
          toast.error("Failed to delete testimonial. Please try again later.");
        }
      })
      .catch((error) => {
        toast.error("Failed to delete testimonial. Please try again later.");
      });
  };

  const handleEdit = (testimonial) => {
    setEditTestimonial(testimonial);
    toggleFormEdit();
  };

  const handleAddTestimonial = (newTestimonial) => {
    setTestimonials([...testimonials, newTestimonial.testimonial]);
  };

  const handleUpdateTestimonial = (updatedTestimonial) => {
    setTestimonials(
      testimonials.map((testimonial) =>
        testimonial.id === updatedTestimonial.testimonial.id
          ? updatedTestimonial.testimonial
          : testimonial
      )
    );
  };

  return (
    <div className="content">
      <div className="w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-mb-2 py-4 flex flex-wrap flex-grow justify-between">
              <div className="flex items-center py-2">
                <button
                  className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleForm}
                >
                  Nouveau témoignage
                </button>
                {showFormAddTestimonial && (
                  <AddTestimonialModal
                    toggleForm={toggleForm}
                    handleAddTestimonial={handleAddTestimonial}
                  />
                )}
                {showFormEditTestimonial && (
                  <UpdateTestimonialModal
                    toggleFormEdit={toggleFormEdit}
                    testimonial={editTestimonial}
                    handleUpdateTestimonial={handleUpdateTestimonial}
                  />
                )}
              </div>
            </div>
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                      <th className="px-6 py-5 text-left font-bold">images</th>
                      <th className="px-6 py-5 text-left font-bold">Noms</th>
                      <th className="px-6 py-5 text-left font-bold">Rôles</th>
                      <th className="px-6 py-5 text-left font-bold">
                        commentaires
                      </th>
                      <th className="px-6 py-5 text-left font-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50 w-full">
                    {testimonials.length > 0 ? (
                      testimonials.map((testimonial) => (
                        <tr key={testimonial.id}>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              <img
                                src={`https://api.essenlux.ma/storage/${testimonial.image}`}
                                alt="avatar"
                                className="w-16 h-16 rounded-full"
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {testimonial.client_name_en}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {testimonial.role_en}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {testimonial.content_en}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap flex  border-b border-gray-200 capitalize gap-3">
                            <button
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800"
                              onClick={() => handleEdit(testimonial)}
                            >
                              <svg
                                className="w-4 h-4 text-green-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <button
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-red-100 text-red-800"
                              onClick={() => handleDelete(testimonial.id)}
                            >
                              <svg
                                className="w-4 h-4 text-red-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="5"
                          className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div className="mx-auto my-10 max-w-2xl text-center sm:my-24">
                            <h2 className="mt-4 text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                              Aucune donnée disponible pour le moment{" "}
                            </h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
