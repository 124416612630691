import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/style.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { transport } from "../../utils/TransportApi";

const MySwal = withReactContent(Swal);

const DetailsTeam = ({ member, onClose, onUpdateMember }) => {
  const [membres, setMembres] = useState([]);
  const [showFormEditMembre, setShowFormEditMembre] = useState(false);

  const [editFormData, setEditFormData] = useState({
    id: member.id,
    name: member.name,
    role: member.role,
    image: null,
  });

  // edit member
  const toggleFormEdit = () => {
    setShowFormEditMembre(!showFormEditMembre);
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.entries(editFormData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await transport.post(
        `/team/${editFormData.id}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(response.data.message);
      onUpdateMember(response.data);
      setEditFormData({ id: "", name: "", role: "", image: "" });
      toggleFormEdit();
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.error("Validation Error:", error.response.data.errors);
      } else {
        console.error("Error while updating member:", error);
      }
    }
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleFileChangeEdit = (event) => {
    setEditFormData({ ...editFormData, image: event.target.files[0] });
  };

  // Fonction pour gérer la suppression d'un membre
  const handleDelete = (id) => {
    MySwal.fire({
      html:
        '<div class="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg">' +
        '<div class="py-2 text-center">' +
        '<p class="text-sm text-gray-600 ml-16">Voulez-vous vraiment supprimer ce membre ?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://api.essenlux.ma/api/team/${id}`)
          .then((response) => {
            if (response && response.data) {
              Swal.fire({
                text: `Membre supprimé avec succès`,
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "custom-confirm-button",
                  cancelButton: "custom-cancel-button",
                },
              });
              setMembres(membres.filter((membre) => membre.id !== id));
              onClose();
            } else {
              Swal.fire({
                text: `Une erreur s'est produite`,
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Réessayer",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "custom-confirm-button",
                  cancelButton: "custom-cancel-button",
                },
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              text: `Une erreur s'est produite`,
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "Réessayer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
              },
            });
          });
      }
    });
  };

  useEffect(() => {
    const fetchMembres = async () => {
      try {
        const response = await transport.get(
          "https://api.essenlux.ma/api/getAllTeam"
        );
        setMembres(response.data);
      } catch (error) {
        console.error("Error fetching the team data:", error);
      }
    };
    fetchMembres();
  }, []);

  return (
    <div className="content fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-75">
      <div className="bg-white rounded-lg shadow w-[40%] mx-auto relative">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
          <h3 className="text-2xl font-semibold text-[#CBA660]">
            Détails de membre
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="p-4 md:p-5">
          <div className="relative mb-4 rounded-2xl">
            <div key={member.id} className="w-full ">
              <div className="flex justify-center items-center">
                <img
                  src={`https://api.essenlux.ma/storage/${member.image}`}
                  alt="team"
                  className="w-[300px] h-[300px] mt-3 rounded-full "
                />
              </div>
              <h2 class="text-2xl capitalize text-start font-black text-[#CBA660]">
                {member.name}
              </h2>
              <span class="text-sm font-black text-[#22213D] uppercase ">
                {member.role}
              </span>
            </div>
          </div>
          <div className="mt-8">
            <button
              type="button"
              onClick={toggleFormEdit}
              className="bg-green-100 text-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {showFormEditMembre ? "Fermer le formulaire" : "Modifier"}
            </button>
            {showFormEditMembre && (
              <div
                id="authentication-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75 rounded-md"
              >
                <div className="relative p-4 w-full max-w-md">
                  <div className="bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                      <h3 className="text-xl font-semibold text-[#CBA660]">
                        Modifier Membre
                      </h3>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                        onClick={toggleFormEdit}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="p-4 md:p-5">
                      <form
                        className="space-y-4"
                        encType="multipart/form-data"
                        onSubmit={handleEditFormSubmit}
                      >
                        <div>
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                          >
                            Nom et Prénom
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={editFormData.name}
                            onChange={handleEditInputChange}
                            placeholder="Nom et Prénom"
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="role"
                            className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                          >
                            Rôle
                          </label>
                          <input
                            type="text"
                            name="role"
                            id="role"
                            value={editFormData.role}
                            onChange={handleEditInputChange}
                            placeholder="Rôle"
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="image"
                            className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                          >
                            Image
                          </label>
                          <input
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleFileChangeEdit}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          />
                        </div>
                        <button
                          type="submit"
                          className="text-white bg-[#CBA660] hover:bg-[#22213D] focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Modifier
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={() => handleDelete(member.id)}
              type="button"
              className="mx-2  bg-red-100 text-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsTeam;
