import React, { useState } from "react";
import ProductDetails from "./ProductDetails";

const ProductCards = ({
  products,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const [activeProductId, setActiveProductId] = useState(null);

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleOpenModal = (productId) => {
    setActiveProductId(productId);
  };

  const handleCloseModal = () => {
    setActiveProductId(null);
  };

  return (
    <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
        <table className="min-w-full">
          {/* <!-- HEAD start --> */}
          <thead>
            <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4   tracking-wider capitalize">
              <th className="px-6 py-5 text-left font-bold">Nom du bien</th>
              <th className="px-6 py-5 text-left font-bold">Status</th>
              <th className="px-6 py-5 text-left font-bold">Ville</th>
              <th className="px-6 py-5 text-left font-bold">Surface</th>
              <th className="px-6 py-5 text-left font-bold">Détails</th>
            </tr>
          </thead>
          {/* <!-- HEAD end --> */}
          {/* <!-- BODY start --> */}
          <tbody className="bg-gray-50 w-full">
            {products.map((product) => (
              <tr key={product.id}>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className="text-sm leading-5 text-gray-900">
                    {product.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className="text-sm leading-5 text-gray-900">
                    {product.status}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {product.ville}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {product.surface} m<sup>2</sup>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 capitalize gap-3">
                  <button
                    className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-indigo-100 text-indigo-800"
                    onClick={() => handleOpenModal(product.id)}
                  >
                    Voir détails
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {activeProductId && (
          <ProductDetails
            product={products.find((product) => product.id === activeProductId)}
            onClose={handleCloseModal}
          />
        )}
      </div>
      <div className="flex justify-end items-center p-4">
        {/* Bouton précédent */}
        {currentPage > 1 && (
          <button
            onClick={handlePrevPage}
            className="p-2 rounded-md text-blue-700 relative"
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h14M5 12l4-4m-4 4 4 4"
              />
            </svg>
          </button>
        )}

        {/* Affichage de la page actuelle et le nombre total de pages */}
        <p className="text-gray-500 text-sm px-2">
          Page {currentPage} of {totalPages}
        </p>
        {/* Bouton suivant */}
        {currentPage < totalPages && (
          <button
            onClick={handleNextPage}
            className="p-2 rounded-md text-blue-700 relative"
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
export default ProductCards;
