import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./context";
import Loader from "../components/Loader/Loader";

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn, loading } = useAuth();

    if (loading) {
        return <Loader />;
    }

    return isLoggedIn ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;