import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-toastify/dist/ReactToastify.css";
import { transport } from "../../utils/TransportApi";

const MySwal = withReactContent(Swal);

const UpdateService = ({ service, toggleFormEdit, handleUpdateService }) => {
  const { register, handleSubmit, setValue } = useForm();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (service) {
      setValue("service_name_ar", service.service_name_ar);
      setValue("service_name_fr", service.service_name_fr);
      setValue("service_name_en", service.service_name_en);
      setValue("service_name_es", service.service_name_es);
      setValue("title_ar", service.title_ar);
      setValue("title_fr", service.title_fr);
      setValue("title_en", service.title_en);
      setValue("title_es", service.title_es);
      setValue("description_ar", service.description_ar);
      setValue("description_fr", service.description_fr);
      setValue("description_en", service.description_en);
      setValue("description_es", service.description_es);
      setValue("status", service.status);
    }
  }, [service, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("service_name_ar", data.service_name_ar);
    formData.append("service_name_fr", data.service_name_fr);
    formData.append("service_name_en", data.service_name_en);
    formData.append("service_name_es", data.service_name_es);
    formData.append("title_ar", data.title_ar);
    formData.append("title_fr", data.title_fr);
    formData.append("title_en", data.title_en);
    formData.append("title_es", data.title_es);
    formData.append("description_ar", data.description_ar);
    formData.append("description_fr", data.description_fr);
    formData.append("description_en", data.description_en);
    formData.append("description_es", data.description_es);
    formData.append("status", data.status);
    if (image) {
      formData.append("image", image);
    }

    await transport
      .post(`/services/${service.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // handleUpdateService(response.data.service);
          MySwal.fire({
            icon: "success",
            title: "Succès",
            text: "Le Service a été modifié",
          });
          toggleFormEdit();
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(
          "Une erreur s'est produite lors de la mise à jour du service"
        );
      });
  };

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-full max-w-2xl">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-2xl font-semibold text-[#CBA660]">
              Modifier votre Service
            </h3>
            <button
              type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={toggleFormEdit}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Fermer le modal</span>
            </button>
          </div>
          <div className="overflow-y-auto max-h-[80vh] max-w-2xl mx-auto p-6">
            <form
              className="max-w-2xl mx-auto"
              encType="multipart/form-data"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="service_name_ar"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Nom du Service (AR)
                  </label>
                  <input
                    type="text"
                    id="service_name_ar"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("service_name_ar")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="service_name_fr"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Nom du Service (FR)
                  </label>
                  <input
                    type="text"
                    id="service_name_fr"
                    {...register("service_name_fr")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="service_name_en"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Nom du Service (EN)
                  </label>
                  <input
                    type="text"
                    id="service_name_en"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("service_name_en")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="service_name_es"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Nom du Service (ES)
                  </label>
                  <input
                    type="text"
                    id="service_name_es"
                    {...register("service_name_es")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="title_ar"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre (AR)
                  </label>
                  <input
                    type="text"
                    id="title_ar"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("title_ar")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="title_fr"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre (FR)
                  </label>
                  <input
                    type="text"
                    id="title_fr"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("title_fr")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="title_en"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre (EN)
                  </label>
                  <input
                    type="text"
                    id="title_en"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("title_en")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="title_es"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre (ES)
                  </label>
                  <input
                    type="text"
                    id="title_es"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("title_es")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="description_ar"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description (AR)
                  </label>
                  <textarea
                    id="description_ar"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("description_ar")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="description_fr"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description (FR)
                  </label>
                  <textarea
                    id="description_fr"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("description_fr")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="description_en"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description (EN)
                  </label>
                  <textarea
                    id="description_en"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("description_en")}
                  />
                </div>
                <div>
                  <label
                    htmlFor="description_es"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description (ES)
                  </label>
                  <textarea
                    id="description_es"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    {...register("description_es")}
                  />
                </div>
                <div>
                  <select
                    id="status"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    hidden
                    {...register("status", { required: true })}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="image"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    id="image"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-auto text-white bg-[#CBA660] hover:bg-[#22213D] font-semibold py-3 px-6 rounded-md mt-6 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Ajouter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateService;
