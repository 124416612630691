import React from "react";
import "../styles/content.css";
import Tabs from "./Tabs";

const Content = () => {
  return (
    <div className="content">
      <h1 className=" font-bold text-4xl p-4 text-[#CBA660]">Admin Panel</h1>
      <Tabs />
    </div>
  );
};

export default Content;
