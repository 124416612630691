import React, { useState, useEffect } from "react";
import Card from "./Card";
import { Link } from "react-router-dom";
import { baseURL, transport } from "../utils/TransportApi";

function Tabs() {
  const [typesWithSubTypes, setTypesWithSubTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [products, setProducts] = useState([]);
  const token = sessionStorage.getItem("tokenApi");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all types and sub-types from the API
        const response = await transport.get("/getAllTypes", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });

        if (response.data) {
          setTypesWithSubTypes(response.data);
          if (response.data.length > 0) {
            const firstType = response.data[0].name;
            setSelectedType(firstType);
            fetchProductsByType(firstType);
          }
        }
      } catch (error) {
        console.error("Error fetching types and subtypes:", error);
      }
    };

    fetchData();
  }, [token]);

  const fetchProductsByType = async (type) => {
    try {
      const response = await transport.get(`/getProductsByType/${type}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      if (response.data) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error("Error fetching products by type:", error);
    }
  };

  const handleTypeClick = (type) => {
    setSelectedType(type);
    fetchProductsByType(type);
  };

  return (
    <div className="mx-auto mt-4 ">
      <div className="flex justify-between items-center p-8">
        <div className="rounded-lg border border-gray-300 bg-white py-2 px-3">
          <nav className="flex gap-4">
            {typesWithSubTypes.map((type) => (
              <span
                href=""
                onClick={() => handleTypeClick(type.name)}
                className={`inline-flex rounded-lg py-2 px-3 text-sm font-medium transition-all duration-200 ease-in-out cursor-pointer ${
                  selectedType === type.name
                    ? "bg-[#CBA660] text-white"
                    : "text-gray-600"
                }`}
                key={type.id}
              >
                {type.name}
              </span>
            ))}
          </nav>
        </div>
        <Card />
      </div>
      <div className="mt-4 px-6 p-6 border border-gray-200 rounded-2xl">
        {products.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {products.map((product) => (
              <article
                key={product.id}
                className="bg-gray-50 p-6 mb-6 shadow transition duration-300 group transform hover:-translate-y-2 hover:shadow-2xl rounded-2xl cursor-pointer border"
              >
                <Link to="/products">
                  <div className="mb-4 rounded-2xl">
                    <h1 className="capitalize mt-3 font-bold text-[#22213D]">
                      {product.name} <span>à</span> {product.ville}
                    </h1>
                    <div className="flex justify-between items-center mb-4">
                      <span className="capitalize text-sm text-indigo-800 bg-indigo-100 p-1 rounded-full">
                        {product.status}
                      </span>
                      <h1 className="capitalize mt-3 bg-green-100 text-green-900 p-2 rounded-full">
                        {product.product_type.name}
                      </h1>
                    </div>
                    <span className="text-md flex">
                      <div>
                        <span className="hidden sm:inline-block capitalize">
                          {product.get_sub_types.name}
                        </span>
                      </div>
                    </span>
                  </div>
                </Link>
              </article>
            ))}
          </div>
        ) : (
          <div className="mx-auto my-10 max-w-2xl text-center sm:my-24">
            <h2 className="mt-4 text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
              Aucune donnée disponible pour le moment{" "}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tabs;
