import React from "react";
import { FaCheck } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";

const PackDetails = ({ packDetails, onClose }) => {
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <span
              className={`capitalize text-sm p-2 rounded-full ${
                packDetails.status
                  ? "text-green-800 bg-green-100"
                  : "text-indigo-800 bg-indigo-100"
              }`}
            >
              {packDetails.status ? "Publié" : "Non publié"}
            </span>
            <button
              type="button"
              className="end-2.5 self-end text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-6 text-center">
            <h5 className="antialiased text-[#CBA660] tracking-normal font-sans text-xl font-semibold leading-snug text-accent flex justify-center mt-2 mb-2">
              {packDetails.pack_type}
            </h5>
            <span className="antialiased tracking-normal font-sans text-base leading-snug text-blue-gray-900 flex justify-center mt-5 mb-2">
              {packDetails.description}
            </span>
            <h3 className="antialiased text-[#CBA660] tracking-normal font-sans text-3xl font-semibold leading-snug text-blue-gray-900 flex justify-center mt-5 mb-2">
              {packDetails.price}DH
              <span className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900 self-end -translate-y-1">
                /per {packDetails.price_type}
              </span>
            </h3>
            <div className="w-full items-center flex justify-center">
              <hr className="px-6 w-2/3 " />
            </div>
            <ul className="flex flex-col items-center justify-start gap-3 pt-6 mt-1 mb-5">
              <div className="">
                {packDetails.services
                  ? JSON.parse(packDetails.services).map((service, index) => (
                      <li
                        key={index + 1}
                        className="flex items-center gap-2 py-1 text-gray-700"
                      >
                        <FaCheck
                          className="w-5 h-5 text-inherit text-[#CBA660]"
                          color="#CBA660"
                        />
                        <p className="block antialiased font-sans text-base leading-relaxed font-normal text-inherit">
                          {service}
                        </p>
                      </li>
                    ))
                  : null}
                {packDetails.services_limited
                  ? JSON.parse(packDetails.services_limited).map(
                      (service_limit, index) => (
                        <li
                          key={index + 1.5}
                          className="flex items-center gap-2 py-1 text-gray-700"
                        >
                          <FaStar
                            className="w-5 h-5 text-inherit"
                            color="#CBA660"
                          />
                          <p className="block antialiased font-sans text-base leading-relaxed font-normal text-inherit">
                            {service_limit}
                          </p>
                        </li>
                      )
                    )
                  : null}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackDetails;
