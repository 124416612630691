import React, { useEffect, useState } from "react";
import { transport } from "../../utils/TransportApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";

const MySwal = withReactContent(Swal);

const TypesWithSubtype = () => {
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSub, setCurrentPageSub] = useState(1);
  const typesPerPage = 6;
  const subTypesPerPage = 6;

  useEffect(() => {
    transport
      .get("/getAllTypes")
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
      });

    transport
      .get("/getAllSubTypes")
      .then((response) => {
        setSubTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
      });
  }, []);

  //delete subtype starts here
  const handleDelete = (id) => {
    MySwal.fire({
      html:
        '<div class="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg ">' +
        '<div class="py-2 text-center">' +
        '<p class="text-sm text-gray-600  ml-16">Voulez-vous vraiment supprimer ce partenaire ?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false, // Disable default styles to ensure custom classes are applied
    }).then((result) => {
      if (result.isConfirmed) {
        transport
          .delete(`/subtypes/${id}`)
          .then((response) => {
            setSubTypes((prevSubTypes) =>
              prevSubTypes.filter((subtype) => subtype.id !== id)
            );
            Swal.fire({
              text: `SubType supprimé avec succès`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
              },
            });
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    });
  };

  //delete type starts here
  const handleDeleteType = (id) => {
    MySwal.fire({
      html:
        '<div class="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg ">' +
        '<div class="py-2 text-center">' +
        '<p class="text-sm text-gray-600  ml-16">Voulez-vous vraiment supprimer ce partenaire ?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false, // Disable default styles to ensure custom classes are applied
    }).then((result) => {
      if (result.isConfirmed) {
        transport
          .delete(`/producttypes/${id}`)
          .then((response) => {
            setTypes((prevTypes) => prevTypes.filter((type) => type.id !== id));
            Swal.fire({
              text: `Product Type supprimé avec succès`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
              },
            });
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    });
  };

  // Calculate the products to display on the current page
  const indexOfLastType = currentPage * typesPerPage;
  const indexOfFirstType = indexOfLastType - typesPerPage;
  const currentTypes = types.slice(indexOfFirstType, indexOfLastType);

  const totalPages = Math.ceil(types.length / typesPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate the subtypes to display on the current page
  const indexOfLastSubType = currentPageSub * subTypesPerPage;
  const indexOfFirstSubType = indexOfLastSubType - subTypesPerPage;
  const currentSubTypes = subTypes.slice(
    indexOfFirstSubType,
    indexOfLastSubType
  );

  const totalPagesSubType = Math.ceil(subTypes.length / subTypesPerPage);

  const handlePageChangeSubType = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPagesSubType) {
      setCurrentPageSub(pageNumber);
    }
  };

  const pageNumbersSubTypes = [];
  for (let i = 1; i <= totalPagesSubType; i++) {
    pageNumbersSubTypes.push(i);
  }

  const pageNumbersTypes = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbersTypes.push(i);
  }

  return (
    <div className="content">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 -mb-2 py-4">
        <div className="flex flex-col">
          <div className="flex items-center py-2">
            <a
              href="/dashboard"
              className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
              type="button"
            >
              Nouveau Type
            </a>
          </div>
          <div className="w-full flex flex-row gap-3">
            <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                    <th className="px-6 py-5 text-left font-bold">Type</th>
                    <th className="px-6 py-5 text-left font-bold">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-50 w-full">
                  {currentTypes.map((type) => (
                    <tr key={type.id}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm leading-5 text-gray-900">
                          {type.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 capitalize gap-3">
                        <button
                          className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-red-100 text-red-800"
                          onClick={() => handleDeleteType(type.id)}
                        >
                          <svg
                            className="w-4 h-4 text-red-800"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-center mt-4 mb-1">
                <nav className="flex space-x-2" aria-label="Pagination">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`relative inline-flex items-center px-4 py-2 text-sm bg-gradient-to-r bg-[#22213D] border border-fuchsia-100 hover:border-violet-100 text-white font-semibold cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 mx-1 ${
                      currentPage === 1
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-[#22213D]"
                    }`}
                  >
                    <svg
                      className="w-6 h-6 dark:text-white logo-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14.707 6.293a1 1 0 010 1.414L10.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {pageNumbersTypes.map((number) => (
                    <button
                      key={number}
                      onClick={() => handlePageChange(number)}
                      className={`mx-1 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-fuchsia-100cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 ${
                        currentPage === number
                          ? "bg-[#E1A835] text-black"
                          : "bg-[#E1A835]"
                      }`}
                    >
                      {number}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`mx-1 relative inline-flex items-center px-4 py-2 text-sm bg-gradient-to-r bg-[#22213D] border border-fuchsia-100 hover:border-violet-100 text-white font-semibold cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 ${
                      currentPage === totalPages
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-[#22213D]"
                    }`}
                  >
                    <svg
                      className="w-6 h-6 dark:text-white logo-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 16.293a1 1 0 010-1.414L13.586 11 9.293 6.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
            <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                    <th className="px-6 py-5 text-left font-bold">Type</th>
                    <th className="px-6 py-5 text-left font-bold">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-50 w-full">
                  {currentSubTypes.map((subtype) => (
                    <tr key={subtype.id}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm leading-5 text-gray-900">
                          {subtype.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 capitalize gap-3">
                        <button
                          className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-red-100 text-red-800"
                          onClick={() => handleDelete(subtype.id)}
                        >
                          <svg
                            className="w-4 h-4 text-red-800"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-center mt-4 mb-1">
                <nav className="flex space-x-2" aria-label="Pagination">
                  <button
                    onClick={() => handlePageChangeSubType(currentPageSub - 1)}
                    disabled={currentPageSub === 1}
                    className={`relative inline-flex items-center px-4 py-2 text-sm bg-gradient-to-r bg-[#22213D] border border-fuchsia-100 hover:border-violet-100 text-white font-semibold cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 mx-1 ${
                      currentPageSub === 1
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-[#22213D]"
                    }`}
                  >
                    <svg
                      className="w-6 h-6 dark:text-white logo-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14.707 6.293a1 1 0 010 1.414L10.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {pageNumbersSubTypes.map((number) => (
                    <button
                      key={number}
                      onClick={() => handlePageChangeSubType(number)}
                      className={`mx-1 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-fuchsia-100cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 ${
                        currentPageSub === number
                          ? "bg-[#E1A835] text-black"
                          : "bg-[#E1A835]"
                      }`}
                    >
                      {number}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChangeSubType(currentPageSub + 1)}
                    disabled={currentPageSub === totalPagesSubType}
                    className={`mx-1 relative inline-flex items-center px-4 py-2 text-sm bg-gradient-to-r bg-[#22213D] border border-fuchsia-100 hover:border-violet-100 text-white font-semibold cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 ${
                      currentPageSub === totalPagesSubType
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-[#22213D]"
                    }`}
                  >
                    <svg
                      className="w-6 h-6 dark:text-white logo-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 16.293a1 1 0 010-1.414L13.586 11 9.293 6.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypesWithSubtype;
