import React, { useState, useEffect } from "react";
import AddService from "./AddService";
import { toast } from "react-toastify";
import ServicesDetails from "./ServicesDetails";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UpdateService from "./UpdateService";
import { transport } from "../../utils/TransportApi";

const MySwal = withReactContent(Swal);

const Services = () => {
  const [showFormAddService, setShowFormAddService] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [activeServiceId, setActiveServiceId] = useState(null);
  const [showFormEditService, setShowFormEditService] = useState(false);
  const [editService, setEditService] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await transport.get(`/services?page=${currentPage}`);
        setServices(response.data.data);
        setTotalPages(response.data.last_page);
      } catch (error) {
        console.error("Erreur lors de la récupération des services:", error);
      }
    };

    fetchServices();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggleForm = () => {
    setShowFormAddService(!showFormAddService);
  };

  const handleServiceAdded = (newService) => {
    setServices((prevServices) => [...prevServices, newService]);
  };

  const toggleFormEdit = () => {
    setShowFormEditService(!showFormEditService);
  };

  // update status
  const handleStatusChange = (id, newStatus) => {
    transport
      .post(`/service-status/${id}`, {
        status: newStatus,
      })
      .then((response) => {
        if (response.status === 200) {
          setServices((handlePrevPage) =>
            handlePrevPage.map((service) =>
              service.id === id ? { ...service, status: newStatus } : service
            )
          );
        }
        toast.success("service mis à jour avec succès !");
      })
      .catch((error) => {
        toast.error(
          "Une erreur s'est produite lors de la mise à jour du statut"
        );
      });
  };

  const handleOpenModal = (id) => {
    const selectedService = services.find((service) => service.id === id);
    setServiceDetails(selectedService);
    setActiveServiceId(id);
  };

  const handleCloseModal = () => {
    setActiveServiceId(null);
  };

  const handleEdit = (service) => {
    setEditService(service);
    toggleFormEdit();
  };

  const handleUpdateService = (handleUpdateService) => {
    setServices(
      services.map((service) =>
        service.id === handleUpdateService.service.id
          ? handleUpdateService.service
          : service
      )
    );
  };

  const handleDelete = (id) => {
    MySwal.fire({
      html:
        '<div class="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg">' +
        '<div class="py-2 text-center">' +
        '<p class="text-sm text-gray-600 ml-16">Voulez-vous vraiment supprimer le service?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        transport
          .delete(`/services/${id}`)
          .then((response) => {
            if (response && response.data) {
              Swal.fire({
                text: `service supprimé avec succès !`,
                icon: "success",
              });
            }
            setServices(services.filter((service) => service.id !== id));
          })
          .catch((error) => {
            Swal.fire({
              text: `Une erreur s'est produite`,
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "Réessayer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
              },
            });
          });
      }
    });
  };

  return (
    <div className="content">
      <div className="w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-mb-2 py-4 flex flex-wrap flex-grow justify-between">
              <div className="flex items-center py-2">
                <button
                  className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleForm}
                >
                  Nouveau service
                </button>
                {showFormAddService && (
                  <AddService
                    toggleForm={toggleForm}
                    onServiceAdded={handleServiceAdded}
                  />
                )}
                {activeServiceId && (
                  <ServicesDetails
                    serviceDetails={serviceDetails}
                    onClose={handleCloseModal}
                  />
                )}
                {showFormEditService && (
                  <UpdateService
                    toggleFormEdit={toggleFormEdit}
                    service={editService}
                    handleUpdateService={handleUpdateService}
                  />
                )}
              </div>
            </div>
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                      <th className="px-6 py-5 text-left font-bold">
                        Nom du service
                      </th>
                      <th className="px-6 py-5 text-left font-bold">Titre</th>
                      <th className="px-6 py-5 text-left font-bold">
                        Description
                      </th>
                      <th className="px-6 py-5 text-left font-bold">Status</th>
                      <th className="px-6 py-5 text-left font-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50 w-full">
                    {services.length > 0 ? (
                      services.map((service) => (
                        <tr key={service.id}>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {service.service_name_fr}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {service.title_fr}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-sm leading-5 text-gray-900">
                              {service.description_fr}
                            </div>
                          </td>
                          <td className="px-6 py-4 border-b border-gray-200  text-start">
                            <label className="inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={service.status}
                                onChange={() =>
                                  handleStatusChange(
                                    service.id,
                                    !service.status
                                  )
                                }
                              />
                              <div
                                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer ${
                                  service.status
                                    ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full"
                                    : ""
                                } after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${
                                  service.status
                                    ? "peer-checked:bg-green-500"
                                    : "peer-checked:bg-red-500"
                                }`}
                              ></div>
                            </label>
                          </td>
                          <td className="px-6 py-4 text-center flex items-center flex-row">
                            {" "}
                            <button
                              onClick={() => handleOpenModal(service.id)}
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800"
                            >
                              <svg
                                className="w-4 h-4 text-sky-600"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                              </svg>
                            </button>
                            <button
                              onClick={() => handleEdit(service)}
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800"
                            >
                              <svg
                                className="w-4 h-4 text-green-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <button
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-red-100 text-red-800"
                              onClick={() => handleDelete(service.id)}
                            >
                              <svg
                                className="w-4 h-4 text-red-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div className="mx-auto my-10 max-w-2xl text-center sm:my-24">
                            <h2 className="mt-4 text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                              Aucune donnée disponible pour le moment{" "}
                            </h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* Afficher la pagination */}
              <div className="flex justify-end items-center mt-4">
                <div>
                  <button
                    className={`relative inline-flex items-center px-4 py-2 text-sm bg-gradient-to-r bg-[#22213D] border border-fuchsia-100 hover:border-violet-100 text-white font-semibold cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 mx-1 ${
                      currentPage === 1
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-[#22213D]"
                    }`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    <svg
                      className="w-6 h-6 dark:text-white logo-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14.707 6.293a1 1 0 010 1.414L10.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`mx-1 px-4 py-2 border ${
                        currentPage === index + 1
                          ? "bg-gray-800 text-white"
                          : "bg-gray-200 text-gray-800"
                      } rounded`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <div>
                  <button
                    className={`relative inline-flex items-center px-4 py-2 text-sm bg-gradient-to-r bg-[#22213D] border border-fuchsia-100 hover:border-violet-100 text-white font-semibold cursor-pointer leading-5 rounded-md transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 mx-1 ${
                      currentPage === 1
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-[#22213D]"
                    }`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      className="w-6 h-6 dark:text-white logo-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 16.293a1 1 0 010-1.414L13.586 11 9.293 6.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
