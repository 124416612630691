import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselDetails = ({ carouselImage, onClose }) => {
  return (
    <div className="content fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-75 overflow-auto">
      <div className="absolute bg-white rounded-lg shadow w-[80%] mx-[1024px]">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
          <h3 className="text-2xl font-semibold text-[#CBA660]">
            Détails de Carousel Image
          </h3>
          <button
            type="button"
            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className=" overflow-y-auto max-h-[80vh] p-4 md:p-5">
          {/* Your modal content here */}
          <div className="relative mb-4 rounded-2xl">
            <div>
              <div className="w-full mb-4">
                <div className="mx-auto p-3">
                  <div className="flex space-y-5 text-center gap-3 xl:text-left justify-center xl:space-y-0 flex-wrap md:flex-nowrap sm:justify-between">
                    <div className="relative space-y-5 w-full 8x-auto xl:mx-0">
                      <span
                        className={`capitalize text-sm p-2 rounded-full ${
                          carouselImage.status
                            ? "text-green-800 bg-green-100"
                            : "text-indigo-800 bg-indigo-100"
                        }`}
                      >
                        {carouselImage.status ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                </div>
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  containerClassName="container-with-dots"
                  customTransition="all .5"
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClassName="product-image-item"
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 1,
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1,
                    },
                  }}
                  showDots={false}
                  sliderClassName=""
                  slidesToSlide={1}
                  swipeable
                >
                  {JSON.parse(carouselImage.image_bg).map((imageUrl, index) => (
                    <div className="w-full" key={carouselImage.id + index}>
                      <img
                        key={index}
                        src={`https://api.essenlux.ma/storage/${imageUrl}`}
                        alt={`Image ${index}`}
                        className="w-full h-[300px] mt-3 object-cover"
                      />
                    </div>
                  ))}
                </Carousel>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex flex-row items-start mt-2 space-x-3 align-middle">
                    <span className="text-lg text-left font-semibold text-blue-900">
                      Titre FR:{" "}
                    </span>{" "}
                    <span
                      className="text-primary font-light"
                      style={{ display: "inline-block" }}
                    >
                      {carouselImage.title_fr}
                    </span>
                  </div>
                  <div className="flex flex-row items-start mt-2 space-x-3 align-middle">
                    <span className="text-lg text-left font-semibold text-blue-900">
                      Titre AR:{" "}
                    </span>{" "}
                    <span
                      className="text-primary font-light"
                      style={{ display: "inline-block" }}
                    >
                      {carouselImage.title_ar}
                    </span>
                  </div>
                  <div className="flex flex-row items-start mt-2 space-x-3 align-middle">
                    <span className="text-lg text-left font-semibold text-blue-900">
                      Titre EN:{" "}
                    </span>{" "}
                    <span
                      className="text-primary font-light"
                      style={{ display: "inline-block" }}
                    >
                      {carouselImage.title_en}
                    </span>
                  </div>
                  <div className="flex flex-row items-start mt-2 space-x-3 align-middle">
                    <span className="text-lg text-left font-semibold text-blue-900">
                      Titre ES:{" "}
                    </span>{" "}
                    <span
                      className="text-primary font-light"
                      style={{ display: "inline-block" }}
                    >
                      {carouselImage.title_es}
                    </span>
                  </div>
                </div>
                <hr className="border-gray-300 my-3 mx-8" />
                <div className="flex flex-col items-start mt-4 space-x-3">
                  <span className="text-lg text-left font-semibold text-blue-900">
                    Description FR:{" "}
                  </span>{" "}
                  <span className="text-primary">
                    {carouselImage.description_fr}
                  </span>
                </div>
                <div className="flex flex-col items-start mt-4 space-x-3">
                  <span className="text-lg text-left font-semibold text-blue-900">
                    Description AR:{" "}
                  </span>{" "}
                  <span className="text-primary">
                    {carouselImage.description_ar}
                  </span>
                </div>
                <div className="flex flex-col items-start mt-4 space-x-3">
                  <span className="text-lg text-left font-semibold text-blue-900">
                    Description EN:{" "}
                  </span>{" "}
                  <span className="text-primary">
                    {carouselImage.description_en}
                  </span>
                </div>
                <div className="flex flex-col items-start mt-4 space-x-3">
                  <span className="text-lg text-left font-semibold text-blue-900">
                    Description ES:{" "}
                  </span>{" "}
                  <span className="text-primary">
                    {carouselImage.description_es}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselDetails;
