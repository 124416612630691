import React from "react";
import { FaCheck } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";

const ServicesDetails = ({ serviceDetails, onClose }) => {
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-full max-w-3xl">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <span
              className={`capitalize text-sm p-2 rounded-full ${
                serviceDetails.status
                  ? "text-green-800 bg-green-100"
                  : "text-indigo-800 bg-indigo-100"
              }`}
            >
              {serviceDetails.status ? "Activé" : "Désactivé"}
            </span>
            <button
              type="button"
              className="end-2.5 self-end text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
                <span className="sr-only">Close modal</span>
              </svg>
            </button>
          </div>

          <div className="p-6 overflow-y-auto max-h-96">
            <img
              src={`https://api.essenlux.ma/storage/${serviceDetails.image}`}
              alt="Service Image"
              className="sm:w-1/2 md:w-6/12 h-72 mx-auto mt-5 rounded-lg shadow-xl"
            />
            <div className="w-full items-center flex justify-center mt-4">
              <hr className="px-6 w-2/3" />
            </div>

            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Service Names */}
              <div className="text-start">
                <h5 className="text-[#CBA660] font-semibold">
                  Nom du Service (ar):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.service_name_ar}
                  </span>
                </h5>
                <h5 className="text-[#CBA660] font-semibold">
                  Nom du Service (fr):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.service_name_fr}
                  </span>
                </h5>
                <h5 className="text-[#CBA660] font-semibold">
                  Nom du Service (en):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.service_name_en}
                  </span>
                </h5>
                <h5 className="text-[#CBA660] font-semibold">
                  Nom du Service (es):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.service_name_es}
                  </span>
                </h5>
              </div>

              {/* Titles */}
              <div className="text-start">
                <h5 className="text-[#CBA660] font-semibold">
                  Titre (ar):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.title_ar}
                  </span>
                </h5>
                <h5 className="text-[#CBA660] font-semibold">
                  Titre (fr):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.title_fr}
                  </span>
                </h5>
                <h5 className="text-[#CBA660] font-semibold">
                  Titre (en):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.title_en}
                  </span>
                </h5>
                <h5 className="text-[#CBA660] font-semibold">
                  Titre (es):{" "}
                  <span className="text-gray-700 font-medium text-sm">
                    {serviceDetails.title_es}
                  </span>
                </h5>
              </div>

              {/* Descriptions */}
              <div className="md:col-span-2 text-start font-medium">
                <h5 className="text-[#CBA660] font-semibold">
                  Description (ar):
                </h5>{" "}
                <p className="text-gray-700 text-sm leading-snug">
                  {serviceDetails.description_ar}
                </p>
                <h5 className="text-[#CBA660] font-semibold">
                  Description (fr):
                </h5>{" "}
                <p className="text-gray-700 text-sm leading-snug">
                  {serviceDetails.description_fr}
                </p>
                <h5 className="text-[#CBA660] font-semibold">
                  Description (en):
                </h5>{" "}
                <p className="text-gray-700 text-sm leading-snug">
                  {serviceDetails.description_en}
                </p>{" "}
                <h5 className="text-[#CBA660] font-semibold">
                  Description (es):
                </h5>{" "}
                <p className="text-gray-700 text-sm leading-snug">
                  {serviceDetails.description_es}
                </p>
              </div>
            </div>

            {/* Services and Services Limited */}
            <ul className="flex flex-col items-center justify-start gap-3 pt-6 mt-1 mb-5">
              {serviceDetails.services &&
                JSON.parse(serviceDetails.services).map((service, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 py-1 text-gray-700"
                  >
                    <FaCheck
                      className="w-5 h-5 text-inherit text-[#CBA660]"
                      color="#CBA660"
                    />
                    <p className="block font-sans text-base leading-relaxed">
                      {service}
                    </p>
                  </li>
                ))}
              {serviceDetails.services_limited &&
                JSON.parse(serviceDetails.services_limited).map(
                  (service_limit, index) => (
                    <li
                      key={index}
                      className="flex items-center gap-2 py-1 text-gray-700"
                    >
                      <FaStar
                        className="w-5 h-5 text-inherit"
                        color="#CBA660"
                      />
                      <p className="block font-sans text-base leading-relaxed">
                        {service_limit}
                      </p>
                    </li>
                  )
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesDetails;
