import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./styles.css";
import "react-multi-carousel/lib/styles.css";
import { useForm } from "react-hook-form";
import { transport } from "../../utils/TransportApi";
const MySwal = withReactContent(Swal);

const AddCarouselImage = ({ toggleForm }) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const { register, handleSubmit, reset, setValue } = useForm();

  const handleFileChange = (e) => {
    let { name, files } = e.target;
    setSelectedFiles(e.target.files);
    setValue(name, files);
  };

  const handleFormSubmit = (data) => {
    if (!selectedFiles) {
      MySwal.fire({
        icon: "error",
        title: "Erreur",
        text: "Veuillez sélectionner des fichiers à télécharger",
      });
      return;
    }

    transport
      .post("/upload-image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Succès",
          text: "Images téléchargées avec succès",
        });
        reset();
        toggleForm();
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
        MySwal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur s'est produite lors du téléchargement des images",
        });
      });
  };

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-full max-w-2xl">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-2xl font-semibold text-[#CBA660] ">
              Télécharger vos images
            </h3>
            <button
              type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={toggleForm}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="overflow-y-auto max-h-[80vh] max-w-2xl mx-auto p-6">
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="max-w-2xl mx-auto"
            >
              <div className="relative border-2 border-dashed border-[#22213D] rounded-md px-6 py-8 text-center">
                <input
                  type="file"
                  className="hidden"
                  id="fileInput"
                  multiple
                  name="image_bg"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleFileChange}
                />
                <svg
                  className="mx-auto h-16 w-16 text-gray-400 mb-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 17l-4 4m0 0l-4-4m4 4V3"
                  ></path>
                </svg>
                <p className="text-sm text-gray-600 ">
                  télécharger vos images{" "}
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer text-blue-500 hover:underline"
                  >
                    ici
                  </label>
                </p>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Titre en Français
                  </label>
                  <input
                    type="text"
                    name="title_fr"
                    id="title_fr"
                    {...register("title_fr")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Titre en Arabe
                  </label>
                  <input
                    type="text"
                    name="title_ar"
                    id="title_ar"
                    {...register("title_ar")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Titre en Anglais
                  </label>
                  <input
                    type="text"
                    name="title_en"
                    id="title_en"
                    {...register("title_en")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Titre en Espagnol
                  </label>
                  <input
                    type="text"
                    name="title_es"
                    id="title_es"
                    {...register("title_es")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="Description"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Description en Français
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_fr"
                    id="description_fr"
                    {...register("description_fr")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Description en Arabe
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_ar"
                    id="description_ar"
                    {...register("description_ar")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Description an Anglais
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_en"
                    id="description_en"
                    {...register("description_en")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Description en Espagnol
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_es"
                    id="description_es"
                    {...register("description_es")}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
              </div>
              <button className="w-auto text-white bg-[#CBA660] hover:bg-[#22213D] font-semibold py-3 px-6 rounded-md mt-6 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                Ajouter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCarouselImage;
