import React, { useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Sidebar } from "./components/Sidebar";
import Products from "./components/Products";
import Partners from "./components/Partners";
import Contacts from "./components/Contacts";
import ErrorPage from "./pages/ErrorPage";
import { AuthProvider, useAuth } from "./utils/context";
import LoginForm from "./pages/LoginForm";
import ProtectedRoute from "./utils/ProtectedRoute"; // Assurez-vous de l'importer
import Dashboard from "./pages/Dashboard";
import "./App.css";
import Team from "./components/Team/Team";
import Testimonial from "./components/Testimonial/Testimonial";
import CarouselImages from "./components/Carousel Images/CarouselImages";
import Loader from "./components/Loader/Loader";
import TypesWithSubtype from "./components/ProductTypes_subtypes/TypesWithSubtype";
import UpdateCarouselImage from "./components/Carousel Images/UpdateCarouselImage";
import PackServices from "./components/PackServices/PackServices";
import Services from "./components/Services/Services";

export const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <AuthProvider>
      <Router>
        <AppContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </Router>
    </AuthProvider>
  );
};

const AppContent = () => {
  const { isLoggedIn, loading } = useAuth();
  const location = useLocation();

  const pathsWithoutSideBar = ["/login", "*"];

  const showSideBar = useMemo(
    () => !pathsWithoutSideBar.includes(location.pathname),
    [location.pathname]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ display: "flex" }}>
      {showSideBar && (
        <Sidebar style={{ flex: "0 0 auto", minWidth: "200px" }} />
      )}
      <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="/products"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partners"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Partners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Contacts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Team />
              </ProtectedRoute>
            }
          />
          <Route
            path="/testimonials"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Testimonial />
              </ProtectedRoute>
            }
          />
          <Route
            path="/headerImages"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <CarouselImages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/types"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <TypesWithSubtype />
              </ProtectedRoute>
            }
          />
          <Route
            path="/headerImages/update"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <UpdateCarouselImage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pack-services"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <PackServices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Services />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<ProtectedRoute />}>
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};
