import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddTestimonialModal = ({ toggleForm, handleAddTestimonial }) => {
  const { register, handleSubmit, reset } = useForm();
  const [image, setImage] = useState(null);

  const onSubmit = async (data) => {
    try {
      const formData = {
        ...data,
        image: image,
      };
      await axios
        .post("https://api.essenlux.ma/api/testimonials", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          handleAddTestimonial(res.data);
          toast.success("Testimonial added successfully!");
        })
        .finally(() => {
          reset();
          toggleForm();
        });
    } catch (error) {
      toast.success(error.response.data.message);
    }
  };
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-1/2">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-2xl font-semibold text-[#CBA660]">
              Ajouter nouveau Témoignage
            </h3>
            <button
              type="button"
              onClick={toggleForm}
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="p-4 md:p-5 grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="client_name_ar"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  الاسم الكامل بالعربية
                </label>
                <input
                  type="text"
                  name="client_name_ar"
                  {...register("client_name_ar")}
                  id="name"
                  placeholder="الاسم بالعربية"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="client_name_en"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Nom complet
                </label>
                <input
                  type="text"
                  name="client_name_en"
                  {...register("client_name_en")}
                  id="name"
                  placeholder=""
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Role_ar"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Rôle in Arabic
                </label>
                <input
                  type="text"
                  name="role_ar"
                  {...register("role_ar")}
                  id="role"
                  placeholder="role"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Role_fr"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Rôle in French
                </label>
                <input
                  type="text"
                  name="role_fr"
                  {...register("role_fr")}
                  id="role"
                  placeholder="role"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Role_en"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Rôle in English
                </label>
                <input
                  type="text"
                  name="role_en"
                  {...register("role_en")}
                  id="role"
                  placeholder="role"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Role_es"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Rôle in Spanish
                </label>
                <input
                  type="text"
                  name="role_es"
                  {...register("role_es")}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Content_ar"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Comment in Arabic
                </label>
                <input
                  type="text"
                  {...register("content_ar")}
                  name="content_ar"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Content_fr"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Comment in French
                </label>
                <input
                  type="text"
                  name="content_fr"
                  {...register("content_fr")}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Content_en"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Comment in English
                </label>
                <input
                  type="text"
                  {...register("content_en")}
                  name="content_en"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Content_es"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                >
                  Comment in Spanish
                </label>
                <input
                  type="text"
                  name="content_es"
                  {...register("content_es")}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="Image"
                  className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                ></label>
                <input
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg"
                  id="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-auto md:mx-5 mb-4 text-white bg-[#CBA660] hover:bg-[#22213D] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Ajouter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTestimonialModal;
