import React from "react";
import Content from "../components/Content";

const Dashboard = () => {
  return (
    <div className="dashboard--content">
      <Content />
    </div>
  );
};

export default Dashboard;
