import React, { useState } from "react";
import Swal from "sweetalert2";
import { transport } from "../../utils/TransportApi";

const UpdateCarouselImage = ({ selectedItem, toggleFormEdit }) => {
  const [formData, setFormData] = useState({
    title_ar: selectedItem.title_ar || "",
    description_ar: selectedItem.description_ar || "",
    title_fr: selectedItem.title_fr || "",
    description_fr: selectedItem.description_fr || "",
    title_en: selectedItem.title_en || "",
    description_en: selectedItem.description_en || "",
    title_es: selectedItem.title_es || "",
    description_es: selectedItem.description_es || "",
    image_bg: null,
    status: selectedItem.status,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image_bg: e.target.files });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      if (key === "image_bg" && formData[key]) {
        Array.from(formData[key]).forEach((file, index) => {
          data.append(`image_bg[${index}]`, file);
        });
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      const response = await transport.post(
        `/carousel-images/${selectedItem.id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Image mise à jour avec succès!",
        });
        toggleFormEdit();
      } else {
        const result = await response.data;
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: `Erreur lors de la mise à jour de l'image : ${result.message}`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: `Erreur lors de la mise à jour de l'image : ${error.message}`,
      });
    }
  };
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="content fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-full max-w-5xl">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-2xl font-semibold text-[#CBA660]">
              Modifier vos images
            </h3>
            <button
              type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={toggleFormEdit}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="overflow-y-auto max-h-[80vh] max-w-5xl mx-auto p-6">
            <form className="max-w-5xl mx-auto" onSubmit={handleSubmit}>
              <div className="relative border-2 border-dashed border-[#22213D] rounded-md px-6 py-8 text-center">
                <input
                  type="file"
                  className="hidden"
                  id="fileInput"
                  multiple
                  onChange={handleFileChange}
                  name="image_bg"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                />
                <svg
                  className="mx-auto h-16 w-16 text-gray-400 mb-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 17l-4 4m0 0l-4-4m4 4V3"
                  ></path>
                </svg>
                <p className="text-sm text-gray-600">
                  télécharger vos images{" "}
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer text-blue-500 hover:underline"
                  >
                    ici
                  </label>
                </p>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <input
                  hidden
                  type="text"
                  name="status"
                  id="status"
                  value={formData.status}
                  onChange={handleInputChange}
                />
                <div>
                  <label
                    htmlFor="title_fr"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre en Français
                  </label>
                  <input
                    type="text"
                    name="title_fr"
                    id="title_fr"
                    value={formData.title_fr}
                    onChange={handleInputChange}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="title_ar"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre en Arabe
                  </label>
                  <input
                    type="text"
                    name="title_ar"
                    id="title_ar"
                    value={formData.title_ar}
                    onChange={handleInputChange}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="title_en"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre en Anglais
                  </label>
                  <input
                    type="text"
                    name="title_en"
                    id="title_en"
                    value={formData.title_en}
                    onChange={handleInputChange}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="title_es"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Titre en Espagnol
                  </label>
                  <input
                    type="text"
                    name="title_es"
                    id="title_es"
                    value={formData.title_es}
                    onChange={handleInputChange}
                    placeholder="Name"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items.center"
                  />
                </div>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="description_fr"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description en Français
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_fr"
                    id="description_fr"
                    value={formData.description_fr}
                    onChange={handleInputChange}
                    placeholder="Description"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items.center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description_ar"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description en Arabe
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_ar"
                    id="description_ar"
                    value={formData.description_ar}
                    onChange={handleInputChange}
                    placeholder="Description"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items.center"
                  />
                </div>
              </div>
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="description_en"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description en Anglais
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_en"
                    id="description_en"
                    value={formData.description_en}
                    onChange={handleInputChange}
                    placeholder="Description"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items.center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description_es"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Description en Espagnol
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    type="text"
                    name="description_es"
                    id="description_es"
                    value={formData.description_es}
                    onChange={handleInputChange}
                    placeholder="Description"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items.center"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-auto text-white bg-[#CBA660] hover:bg-[#22213D] font-semibold py-3 px-6 rounded-md mt-6 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Ajouter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCarouselImage;
