import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { transport } from "../utils/TransportApi";
import { useAuth } from "../utils/context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const { login } = useAuth();

  const onSubmit = async (data) => {
    try {
      const response = await transport.post('/login', data, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.data) {
        const token = response.data.token;
        login(token);
        navigate('/dashboard');
        toast.success("Connexion réussie!");
      } else {
        console.error('Login failed');
      }

    } catch (error) {
      setLoginError("Nom d'utilisateur ou mot de passe invalide. Veuillez réessayer.");
    }
  };

  return (
    <div className="bg-gray-50 flex justify-center items-center h-screen lg:h-full">
      <div className="w-1/2 hidden lg:block overflow-hidden">
        <img
          src={require("../images/desgni_vr.jpg")}
          alt="Placeholder"
          className="w-full h-full"
        />
      </div>
      <div className="lg:px-36 md:px-52 sm:p-20 p-8 w-full lg:w-1/2">
        <div className="rounded-2xl bg-white shadow-xl">
          <form onSubmit={handleSubmit(onSubmit)} action="" className="lg:p-11 p-7 mx-auto" >
            <div className="mb-11">
              <h1 className="text-gray-900 text-center font-manrope text-3xl font-bold leading-10 mb-2">Se connecter à <span className="text-[#CBA660]">EssenLux</span></h1>
            </div>
            <input type="hidden" name="_token" value="{{ csrf_token() }}" />
            <div className="mb-6">
              <input {...register('email', { required: 'Email est requis' })} name="email" type="email" className="w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border-gray-300 border shadow-sm focus:outline-none px-4 mb-1" placeholder="Email" />
              {errors.email && <p className="text-red-500 text-sm px-4">{errors.email.message}</p>}
            </div>
            <div className="mb-6">
              <input type="password"
                {...register('password', { required: 'Password est requis' })} name="password" className="w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border-gray-300 border shadow-sm focus:outline-none px-4 mb-1" placeholder="Mot de passe" />
              {errors.password && <p className="text-red-500 text-sm mb-6 px-4">{errors.password.message}</p>}
            </div>
            {loginError && <p className="text-red-500 text-sm mb-6 px-4">{loginError}</p>}
            <button type="submit" className="w-full h-12 text-white text-center text-base font-semibold leading-6 rounded-full hover:bg-indigo-800 transition-all duration-700 bg-gradient-to-r bg-[#CBA660] shadow-sm mb-11">Se connecter</button>
            {/* <a href="/register" className="flex justify-center text-gray-900 text-base font-medium leading-6">Vous n'avez pas de compte ? <span className="text-indigo-600 font-semibold pl-3"> S'inscrire</span>
            </a> */}
          </form>
        </div>
      </div >
    </div >
  );
}

export default LoginForm;
