import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/style.css";
import { toast } from "react-toastify";
import DetailsTeam from "./DetailsTeam";
import { transport } from "../../utils/TransportApi";

const Team = () => {
  const [membres, setMembres] = useState([]);
  const [showFormAddMembre, setShowFormAddMembre] = useState(false);
  const [activeTeamId, setActiveTeamId] = useState(null);

  const handleOpenModal = (teamId) => {
    setActiveTeamId(teamId);
  };

  const handleCloseModal = () => {
    setActiveTeamId(null);
  };

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    image: null,
  });

  const toggleForm = () => {
    setShowFormAddMembre(!showFormAddMembre);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChangeAddMembre = (event) => {
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });
      const response = await axios.post(
        "https://api.essenlux.ma/api/addTeam",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(response.data.message);
      setMembres([...membres, response.data]);
      setFormData({
        name: "",
        role: "",
        image: null,
      });
      setShowFormAddMembre(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.error("Validation Error:", error.response.data.errors);
      } else {
        toast.error("Error while adding membre");
        console.error("Error while adding membre:", error);
      }
    }
  };

  const handleUpdateMember = (updatedMember) => {
    setMembres((prevMembres) =>
      prevMembres.map((membre) =>
        membre.id === updatedMember.id ? updatedMember : membre
      )
    );
    handleCloseModal();
  };

  useEffect(() => {
    const fetchMembres = async () => {
      try {
        const response = await transport.get(
          "https://api.essenlux.ma/api/getAllTeam"
        );
        setMembres(response.data);
      } catch (error) {
        console.error("Error fetching the team data:", error);
      }
    };
    fetchMembres();
  }, []);

  return (
    <div className="content">
      <div className="w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-mb-2 py-4 flex flex-wrap flex-grow justify-between">
              <div className="flex items-center py-2">
                <button
                  className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleForm}
                >
                  {showFormAddMembre ? "Close Form" : "Nouveau Membre"}
                </button>
                {showFormAddMembre && (
                  <div
                    id="authentication-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
                  >
                    <div className="relative p-4 w-full max-w-md">
                      <div className="relative bg-white rounded-lg shadow ">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                          <h3 className="text-2xl font-semibold text-[#CBA660] ">
                            Ajouter nouveau membre
                          </h3>
                          <button
                            type="button"
                            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={toggleForm}
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                        </div>
                        <div className="p-4 md:p-5">
                          <form
                            onSubmit={handleFormSubmit}
                            className="space-y-4"
                            encType="multipart/form-data"
                          >
                            <div>
                              <label
                                htmlFor="Nom et Prénom"
                                className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                              >
                                Nom et Prénom
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Nom et Prénom"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="Role"
                                className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                              >
                                Rôle
                              </label>
                              <input
                                type="text"
                                name="role"
                                id="role"
                                value={formData.role}
                                onChange={handleInputChange}
                                placeholder="role"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="Image"
                                className="block mb-2 text-sm font-medium text-gray-900 capitalize"
                              >
                                Image
                              </label>
                              <input
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleFileChangeAddMembre}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              />
                            </div>
                            <button
                              type="submit"
                              className="text-white bg-[#CBA660] hover:bg-[#22213D] focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                              Ajouter
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                      <th className="px-6 py-5 text-left font-bold">
                        Nom et prénom
                      </th>
                      <th className="px-6 py-5 text-left font-bold">Rôle</th>
                      <th className="px-6 py-5 text-left font-bold">Détails</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50 w-full">
                    {membres.map((membre) => (
                      <tr key={membre.id}>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <div className="text-sm leading-5 text-gray-900">
                            {membre.name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <div className="text-sm leading-5 text-gray-900">
                            {membre.role}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 capitalize gap-3">
                          <button
                            className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-indigo-100 text-indigo-800"
                            onClick={() => handleOpenModal(membre.id)}
                          >
                            Voir détails
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {activeTeamId && (
                  <DetailsTeam
                    member={membres.find(
                      (member) => member.id === activeTeamId
                    )}
                    onClose={handleCloseModal}
                    onUpdateMember={handleUpdateMember}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
