import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-multi-carousel/lib/styles.css";
import { useForm } from "react-hook-form";
import { transport } from "../../utils/TransportApi";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const MySwal = withReactContent(Swal);

const AddPackService = ({ toggleForm }) => {
  const { register, handleSubmit, reset } = useForm();
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState("");
  const [limitedServices, setLimitedServices] = useState([]);
  const [currentLimitService, setCurrentLimitService] = useState("");

  const handleAddService = () => {
    if (currentService.trim()) {
      setServices([...services, currentService]);
      setCurrentService("");
    }
  };

  const handleRemoveService = (indexToRemove) => {
    setServices(services.filter((_, index) => index !== indexToRemove));
  };

  const handleAddLimitService = () => {
    if (currentLimitService.trim()) {
      setLimitedServices([...limitedServices, currentLimitService]);
      setCurrentLimitService("");
    }
  };

  const handleRemoveLimitService = (indexToRemove) => {
    setLimitedServices(
      limitedServices.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleFormSubmit = (data) => {
    data.services = services.length > 0 ? services : [];
    data.services_limited = limitedServices.length > 0 ? limitedServices : [];
    transport
      .post("/pack-services", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Succès",
          text: "Le Pack Service a été ajouté",
        });
        reset();
        toggleForm();
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur s'est produite",
        });
      });
  };

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
    >
      <div className="relative p-4 w-full max-w-2xl">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-2xl font-semibold text-[#CBA660] ">
              Ajouter un Pack de Service
            </h3>
            <button
              type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={toggleForm}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="overflow-y-auto max-h-[80vh] max-w-2xl mx-auto p-6">
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="max-w-2xl mx-auto"
            >
              <div className="pt-3 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Nom du Pack
                  </label>
                  <input
                    type="text"
                    name="pack_name"
                    id="pack_name"
                    {...register("pack_name")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    {...register("description")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Prix
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    {...register("price")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Type de prix
                  </label>
                  <select
                    name="price_type"
                    id="price_type"
                    className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                    {...register("price_type")}
                  >
                    <option value="" disabled selected>
                      Sélectionner type de prix
                    </option>
                    <option value="mois">Mois</option>
                    <option value="annee">Annee</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Caractéristiques
                  </label>
                  <div className="flex align-middle items-center gap-2 w-full relative">
                    <input
                      type="text"
                      name="services"
                      id="services"
                      value={currentService}
                      onChange={(e) => setCurrentService(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    />
                    <FaCheckCircle
                      onClick={handleAddService}
                      className="bg-green-500 text-white rounded-lg justify-center absolute right-2 cursor-pointer"
                      size={20}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Type de pack
                  </label>
                  <input
                    type="text"
                    name="pack_type"
                    id="pack_type"
                    {...register("pack_type")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                  />
                </div>
                <div className="mt-2 w-full flex flex-row flex-wrap gap-2 col-span-2">
                  {services.map((service, index) => (
                    <div
                      key={index}
                      className="relative flex flex-row gap-1 items-center w-fit bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-3 py-2 rounded-lg"
                    >
                      <span>{service}</span>
                      <IoMdClose
                        onClick={() => handleRemoveService(index)}
                        className="bg-red-500 text-white rounded-lg justify-center relative cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <label
                    htmlFor="Titre"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Caractéristiques limité
                  </label>
                  <div className="flex align-middle items-center gap-2 w-full relative">
                    <input
                      type="text"
                      name="services_limited"
                      id="services_limited"
                      value={currentLimitService}
                      onChange={(e) => setCurrentLimitService(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                    />
                    <FaCheckCircle
                      onClick={handleAddLimitService}
                      className="bg-green-500 text-white rounded-lg justify-center absolute right-2 cursor-pointer"
                      size={20}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2 w-full flex flex-row flex-wrap gap-2">
                {limitedServices.map((service, index) => (
                  <div
                    key={index}
                    className="relative flex flex-row gap-1 items-center w-fit bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-3 py-2 rounded-lg"
                  >
                    <span>{service}</span>
                    <IoMdClose
                      onClick={() => handleRemoveLimitService(index)}
                      className="bg-red-500 text-white rounded-lg justify-center relative cursor-pointer"
                    />
                  </div>
                ))}
              </div>

              <button className="w-auto text-white bg-[#CBA660] hover:bg-[#22213D] font-semibold py-3 px-6 rounded-md mt-6 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                Ajouter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPackService;
