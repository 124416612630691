import React, { useState, useEffect } from "react";
import axios from "axios";

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchContacts(currentPage);
  }, [currentPage]);

  const fetchContacts = async (page) => {
    try {
      const response = await axios.get(
        `https://api.essenlux.ma/api/getAllContacts?page=${page}`
      );
      setContacts(response.data.contacts.data);
      setTotalPages(response.data.contacts.last_page);
    } catch (error) {
      console.error("Failed to fetch contacts");
    }
  };

  const updateConfirmationStatus = async (contactId, status) => {
    try {
      const response = await fetch(
        "https://api.essenlux.ma/api/updateConfirmation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ contactId, status }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update confirmation status");
      }

      // Mettre à jour le statut localement après le succès de la mise à jour
      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact.id === contactId
            ? { ...contact, confirmation_status: status }
            : contact
        )
      );
    } catch (error) {
      console.error("Error updating confirmation status:", error);
    }
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="content">
      <div className="mt-16 w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                      <th className="px-6 py-5 text-left font-bold">nom</th>
                      <th className="px-6 py-5 text-left font-bold">prénom</th>
                      <th className="px-6 py-5 text-left font-bold">email</th>
                      <th className="px-6 py-5 text-left font-bold">
                        téléphone
                      </th>
                      <th className="px-6 py-5 text-left font-bold">demande</th>
                      <th className="px-6 py-5 text-left font-bold">message</th>
                      <th className="px-6 py-5 text-left font-bold">
                        confirmation
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50 w-full">
                    {contacts.length > 0 ? (
                      contacts.map((contact) => (
                        <tr key={contact.id}>
                          <td className="text-sm leading-5 text-gray-900 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {contact.firstName}
                          </td>
                          <td className="text-sm leading-5 text-gray-900 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {contact.lastName}
                          </td>
                          <td className="text-sm leading-5 text-gray-900 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {contact.email}
                          </td>
                          <td className="text-sm leading-5 text-gray-900 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {contact.telephone}
                          </td>
                          <td className="text-sm leading-5 text-gray-900 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {contact.demande}
                          </td>
                          <td className="text-sm leading-5 text-gray-900 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {contact.message}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <select
                              onChange={(e) =>
                                updateConfirmationStatus(
                                  contact.id,
                                  e.target.value
                                )
                              }
                              value={contact.confirmation_status}
                              className=" inline-flex text-xs leading-5 font-semibold bg-indigo-50 rounded-full p-2 text-[#22213D]"
                            >
                              <option value="ok">Confirmé</option>
                              <option value="non">Non confirmé</option>
                              <option value="pas encore vu">
                                Pas encore vu
                              </option>
                            </select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div className="mx-auto my-10 max-w-2xl text-center sm:my-24">
                            <h2 className="mt-4 text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                              Aucune donnée disponible pour le moment{" "}
                            </h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end items-center p-4">
                {/* Bouton précédent */}
                {currentPage > 1 && (
                  <button
                    onClick={handlePrevPage}
                    className="p-2 rounded-md text-blue-700 relative"
                  >
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 12h14M5 12l4-4m-4 4 4 4"
                      />
                    </svg>
                  </button>
                )}

                {/* Affichage de la page actuelle et le nombre total de pages */}
                <p className="text-gray-500 text-sm px-2">
                  Page {currentPage} of {totalPages}
                </p>

                {/* Bouton suivant */}
                {currentPage < totalPages && (
                  <button
                    onClick={handleNextPage}
                    className="p-2 rounded-md text-blue-700 relative"
                  >
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
