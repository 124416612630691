import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transport } from "../utils/TransportApi";

const Card = () => {
  const [showForm, setShowForm] = useState(false);
  const [subTypes, setSubTypes] = useState([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState([]);
  const [showSubTypesForm, setShowSubTypesForm] = useState(false);
  const [isNewType, setIsNewType] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedExistingTypeId, setSelectedExistingTypeId] = useState(null);

  const toggleForm = () => {
    setShowForm(!showForm);
    setShowSubTypesForm(false);
  };

  const toggleSubTypesSelect = () => {
    setShowSubTypesForm(!showSubTypesForm);
  };

  const toggleNewType = () => {
    setIsNewType(!isNewType);
    setSelectedExistingTypeId(null);
    setName("");
  };

  const handleExistingTypeChange = (e) => {
    setSelectedExistingTypeId(e.target.value);
  };

  useEffect(() => {
    transport
      .get("/getAllTypes")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    transport
      .get("/getAllSubTypes")
      .then((response) => {
        setSubTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subtypes:", error);
      });
  }, []);

  const [name, setName] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubTypeChange = (option) => {
    if (selectedSubTypes.includes(option.id)) {
      setSelectedSubTypes(
        selectedSubTypes.filter((item) => item !== option.id)
      );
    } else {
      setSelectedSubTypes([...selectedSubTypes, option.id]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedExistingTypeId) {
      // Update the subtypes of the existing type
      const updatedSubTypes = {
        sub_type_ids: selectedSubTypes,
      };

      axios
        .post(
          `https://api.essenlux.ma/api/producttypes/${selectedExistingTypeId}`,
          updatedSubTypes
        )
        .then((response) => {
          toast.success("Subtypes updated successfully!");
          setShowForm(false);
          // Update the types list
          setProductTypes((prevTypes) =>
            prevTypes.map((type) =>
              type.id === response.data.id ? response.data : type
            )
          );
          setSelectedSubTypes([]);
          setSelectedExistingTypeId(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message);
          } else {
            console.error("Error adding subtypes:", error);
            toast.error("Error adding subtypes!");
          }
        });
    } else {
      const formData = {
        name: name,
        sub_type_ids: selectedSubTypes,
      };

      transport
        .post("/producttypes", formData)
        .then((response) => {
          toast.success("Type de produit ajouté avec succès !");
          setShowForm(false);
          setName("");
          setSelectedSubTypes("");
        })
        .catch((error) => {
          console.error("Erreur lors de l'ajout du type de produit :", error);
          toast.error("Erreur lors de l'ajout du type de produit !");
        });
    }
  };

  // get product types
  useEffect(() => {
    transport
      .get("/getAllTypes")
      .then((response) => {
        setProductTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
      });
  }, []);

  // *****************************subtype form ***********************************
  const [nameSubType, setNameSubType] = useState("");
  const [showFormSubType, setShowFormSubType] = useState(false);

  const handleNameSubTypeChange = (e) => {
    setNameSubType(e.target.value);
  };

  const toggleFormSubType = () => {
    setShowFormSubType(!showFormSubType);
  };

  const handleSubmitSubType = (e) => {
    e.preventDefault();

    const formDataSubType = {
      name: nameSubType,
    };
    transport
      .post("/subtypes", formDataSubType)
      .then((response) => {
        toast.success("subType ajouté avec succès !");
        toggleFormSubType(); // Correction ici
        setNameSubType("");
      })
      .catch((error) => {
        console.error("Erreur lors de l'ajout du subtype :", error);
        toast.error("Erreur lors de l'ajout du subtype !");
      });
  };

  return (
    <div>
      <button
        className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
        type="button"
        onClick={toggleForm}
      >
        {showForm ? "Close Form" : " Nouveau type"}
      </button>
      {showForm && (
        <div className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75">
          <div className="relative p-4 w-full max-w-3xl">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 className="text-2xl font-bold text-[#CBA660]">
                  Nouveau type de bien
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  onClick={toggleForm}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="w-full flex items-end justify-end px-5 ">
                <button
                  className="mt-4 inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] hover:bg-[#22213D] text-white focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleNewType}
                >
                  {isNewType
                    ? "Ajouter un nouveau type"
                    : "Utiliser un type existant"}
                </button>
              </div>
              <div className="p-4 md:p-5">
                <form onSubmit={handleSubmit}>
                  {isNewType && (
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Nom de type de bien
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nom"
                        value={name}
                        onChange={handleNameChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                      />
                    </div>
                  )}

                  {!isNewType && (
                    <div>
                      <label
                        htmlFor="existingType"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Sélectionnez un type existant
                      </label>
                      <select
                        name="existingTypeId"
                        id="existingTypeId"
                        value={selectedExistingTypeId || ""}
                        onChange={handleExistingTypeChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                      >
                        <option value="">Sélectionner un type</option>
                        {productTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="subType"
                      className="block my-2 text-sm font-medium text-gray-900"
                    >
                      Sous type de bien
                    </label>
                    <div className="flex flex-row p-1 justify-center border bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] w-full">
                      <div className="w-full">
                        {selectedSubTypes.length === 0 ? (
                          <h6 className="text-gray-400">
                            séléctionner un sous type...
                          </h6>
                        ) : (
                          selectedSubTypes.map((optionId) => {
                            const selectedOption = subTypes.find(
                              (option) => option.id === optionId
                            );
                            return (
                              <span
                                key={optionId}
                                className="inline-block bg-indigo-500 text-white px-2 py-1 rounded mr-1 mb-1"
                              >
                                {selectedOption ? selectedOption.name : ""}
                              </span>
                            );
                          })
                        )}
                      </div>
                      <button
                        className="mx-4 capitalize px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md bg-[#CBA660] text-white hover:bg-[#22213D] focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={toggleSubTypesSelect}
                      >
                        {showSubTypesForm ? "X" : "O"}
                      </button>
                    </div>

                    {showSubTypesForm && (
                      <div className="border rounded mt-1 absolute bg-white text-black z-10 w-11/12 max-h-60 overflow-y-auto">
                        {subTypes.map((option) => (
                          <div
                            key={option.id}
                            className={`p-2 cursor-pointer ${
                              selectedSubTypes.includes(option.id)
                                ? "bg-blue-100 text-black"
                                : ""
                            }`}
                            onClick={() => handleSubTypeChange(option)}
                          >
                            {option.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="capitalize font-semibold mt-4 inline-block px-4 py-2 border border-transparent text-sm leading-5 rounded-md bg-[#CBA660] text-white hover:bg-[#22213D] focus:outline-none focus:shadow-outline"
                  >
                    envoyer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* *********************************************************** */}
      <button
        className="mx-3 inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
        type="button"
        onClick={toggleFormSubType}
      >
        {showFormSubType ? "Close Form" : " Sous type"}
      </button>
      {showFormSubType && (
        <div className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75">
          <div className="relative p-4 w-full max-w-3xl">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 className="text-2xl font-bold text-[#CBA660]">
                  Nouveau sous type de bien
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  onClick={toggleFormSubType}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <button className="hidden" onClick={toggleFormSubType}>
                  Ajouter
                </button>
                {showFormSubType && (
                  <form className="capitalize" onSubmit={handleSubmitSubType}>
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Nom de sous type
                      </label>
                      <input
                        type="text"
                        name="nameSubType"
                        id="name"
                        value={nameSubType}
                        onChange={handleNameSubTypeChange}
                        placeholder="Name"
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                      />
                    </div>
                    <button
                      type="submit"
                      className="capitalize font-semibold mt-4 inline-block px-4 py-2 border border-transparent text-sm leading-5 rounded-md bg-[#CBA660] text-white hover:bg-[#22213D] focus:outline-none focus:shadow-outline"
                    >
                      envoyer
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* **************************************************** */}
    </div>
  );
};

export default Card;
