import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { transport } from "../utils/TransportApi";
import { useForm } from "react-hook-form";
import { features, interiors, options } from "../utils/helpers";

const MySwal = withReactContent(Swal);

const ProductDetails = ({ product, onClose }) => {
  const [showFormEdit, setShowFormEdit] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  // State pour les produits
  const [products, setProducts] = useState([]);

  // State pour les sous-types
  const [subTypes, setSubTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedInterior, setSelectedInterior] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [existingImages, setExistingImages] = useState(product.images || []);
  const [newImages, setNewImages] = useState([]);

  useEffect(() => {
    if (product) {
      setValue("name", product.name);
      setValue("surface", product.surface);
      setValue("price", product.price);
      // setValue("images", existingImages || []);
      setValue("lien_video", product.lien_video);
      setValue("status", product.status);
      setValue("ville", product.ville);
      setValue("address", product.address);
      setValue("rooms_number", product.rooms_number);
      setValue("description", product.description);
      setValue("product_type_id", product.product_type.id);
      setValue("sub_type_id", product.sub_type_id);
      setValue("douche", product.douche);
      setValue("toillete", product.toillete);
      setValue("soil_type", product.soil_type);
      setValue("product_etat", product.product_etat);
      setValue("orientation", product.orientation);
      setValue("floor", product.floor);
      setSelectedOptions(
        JSON.parse(product.additional_options)
          ? JSON.parse(product.additional_options)
          : []
      );
      setSelectedInterior(
        JSON.parse(product.interior) ? JSON.parse(product.interior) : []
      );
      setSelectedFeatures(
        JSON.parse(product.main_features)
          ? JSON.parse(product.main_features)
          : []
      );
      setSelectedType(product.sub_type_id);
    }
  }, [product, setValue]);

  // Effect pour récupérer les produits
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await transport.get("/products");
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    transport
      .get("/getAllTypes")
      .then((response) => {
        setProductTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
      });
  }, []);

  // Fonction pour basculer le formulaire d'édition
  const toggleFormEdit = () => {
    setShowFormEdit(!showFormEdit);
  };

  // edit data
  const handleUpdate = async (data) => {
    try {
      if (newImages.length > 0) {
        data.images = newImages;
      } else {
        data.images = existingImages;
      }

      // Initialize features, interior, and options as empty arrays if they are null
      data.main_features = data.main_features || [];
      data.interior = data.interior || [];
      data.additional_options = data.additional_options || [];

      data.main_features.push(...selectedFeatures);
      data.interior.push(...selectedInterior);
      data.additional_options.push(...selectedOptions);

      transport.post(`/products/${product.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Product updated successfully!");
      setShowFormEdit(false);
      window.location.reload();
    } catch (error) {
      toast.error("Failed to edit product. Please try again later.");
    }
  };

  // Fonction pour gérer la suppression d'un produit
  const handleDelete = (id) => {
    MySwal.fire({
      html:
        '<div className="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg ">' +
        '<div className="py-2 text-center">' +
        '<p className="text-sm text-gray-600">Voulez-vous vraiment supprimer ce produit?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false, // Disable default styles to ensure custom classes are applied
    }).then((result) => {
      if (result.isConfirmed) {
        transport.delete(`/products/${id}`).then((response) => {
          Swal.fire({
            text: `Product supprimé avec succès`,
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "custom-confirm-button",
              cancelButton: "custom-cancel-button",
            },
          });
          // Mettre à jour la liste des produits après la suppression
          if (Array.isArray(products)) {
            setProducts(products.filter((product) => product.id !== id));
          }
          onClose();
          window.location.reload();
        });
      }
    });
  };

  // Fonction pour gérer le changement de fichier
  const handleFileChange = async (e) => {
    let { name, files } = e.target;

    // Initialize arrays to manage new and existing images
    let newImageFiles = Array.from(files); // Convert FileList to Array
    let uploadedPaths = [...existingImages]; // Existing images paths

    // If new images are selected
    if (newImageFiles.length > 0) {
      setIsLoading(true); // Set loading state
      try {
        // Upload new images and get uploaded paths
        uploadedPaths = await uploadImages(newImageFiles);

        // Concatenate uploaded paths with existing images
        let updatedImages = [...existingImages, ...uploadedPaths];

        // Update newImages state with the File objects for preview or further handling
        setNewImages(updatedImages);

        // Update form value with the updated image paths
        setValue(name, updatedImages);

        // Optionally display success message or perform other actions
        setUploadSuccess(true);
      } catch (error) {
        toast.error("Failed to upload images. Please try again.");
        console.error("Upload error:", error);
      } finally {
        setIsLoading(false); // Reset loading state
      }
    } else {
      // No new images selected, retain existing images
      setNewImages([]);
      setValue(name, existingImages); // Update form value with existing images
    }
  };

  const removeExistingImage = (index) => {
    setExistingImages(existingImages.filter((_, i) => i !== index));
  };

  const uploadImages = async (files) => {
    const batchSize = 10;
    const formDataBatches = [];
    const filesArray = Array.from(files);

    for (let i = 0; i < filesArray.length; i += batchSize) {
      const batch = filesArray.slice(i, i + batchSize);
      const formData = new FormData();
      batch.forEach((file) => formData.append("images[]", file));
      formDataBatches.push(formData);
    }

    setIsLoading(true);
    setUploadSuccess(false);

    try {
      const paths = [];
      for (const formData of formDataBatches) {
        const response = await transport.post("/uploadImages", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        paths.push(...response.data.paths);
      }
      setUploadSuccess(true);
      return paths;
    } catch (error) {
      toast.error("Failed to upload images. Please try again.");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleTypeChange = (event) => {
    const typeId = event.target.value;
    setSelectedType(typeId);
  };

  useEffect(() => {
    // Update subtypes when the selectedType changes
    if (selectedType) {
      const selectedTypeData = productTypes.find(
        (type) => type.id === parseInt(selectedType)
      );
      if (selectedTypeData && selectedTypeData.sub_types) {
        setSubTypes(selectedTypeData.sub_types);
      } else {
        setSubTypes([]);
      }
    }
  }, [selectedType, productTypes]);

  const selectFeature = (featureId) => {
    setSelectedFeatures((prevSelectedFeatures) => {
      if (prevSelectedFeatures.includes(featureId)) {
        // If the featureId is already selected, remove it
        return prevSelectedFeatures.filter((id) => id !== featureId);
      } else {
        // If the featureId is not selected, add it
        return [...prevSelectedFeatures, featureId];
      }
    });
  };

  const selectInterior = (interiorId) => {
    setSelectedInterior((prevSelectedInterior) => {
      if (prevSelectedInterior.includes(interiorId)) {
        return prevSelectedInterior.filter((id) => id !== interiorId);
      } else {
        return [...prevSelectedInterior, interiorId];
      }
    });
  };

  const selectOption = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  // ***************************************************************************************

  if (!product) return null;

  return (
    <div className="content fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-75 overflow-auto">
      <div className="absolute bg-white rounded-lg shadow w-[80%] mx-[1024px]">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
          <h3 className="text-2xl font-semibold text-[#CBA660]">
            Détails de Produit
          </h3>
          <button
            type="button"
            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className=" overflow-y-auto max-h-[80vh] p-4 md:p-5">
          {/* Your modal content here */}
          <div className="relative mb-4 rounded-2xl">
            <div>
              <div key={product.id} className="w-full mb-4">
                <div className="mx-auto p-3">
                  <div className="flex space-y-5 text-center gap-3 xl:text-left justify-center xl:space-y-0 flex-wrap md:flex-nowrap sm:justify-between">
                    <div className="relative space-y-5 w-full 8x-auto xl:mx-0">
                      <span className="capitalize text-sm text-indigo-800 bg-indigo-100 p-2 rounded-full">
                        {product.status}
                      </span>
                      <h1 className="capitalize text-2xl font-black leading-snug text-text">
                        {product.name} <span>à</span> {product.ville}
                      </h1>

                      <ul className="flex text-primary justify-center xl:justify-normal space-x-6 w-full flex-wrap">
                        <li className="inline-flex space-x-3 items-center">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708L5.793 1Zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207l-4.5-4.5Z"></path>
                          </svg>
                          <span className="text-md flex">
                            <div>
                              <span className="hidden sm:inline-block capitalize">
                                {product.get_sub_types.name}
                              </span>
                            </div>
                          </span>
                        </li>

                        <li className="inline-flex space-x-3 items-center">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M20 9.557V3h-2v2H6V3H4v6.557C2.81 10.25 2 11.525 2 13v4a1 1 0 0 0 1 1h1v4h2v-4h12v4h2v-4h1a1 1 0 0 0 1-1v-4c0-1.475-.811-2.75-2-3.443zM18 7v2h-5V7h5zM6 7h5v2H6V7zm14 9H4v-3c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2v3z"></path>
                          </svg>
                          <span className="text-md ">
                            <span className="hidden sm:inline-block  space-x-3">
                              Chambres :{" "}
                            </span>{" "}
                            {product.rooms_number}
                          </span>
                        </li>

                        <li className="inline-flex space-x-3 items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            height="1em"
                            width="1em"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819"
                            ></path>
                          </svg>
                          <span className="text-md space-x-3">
                            <span className="hidden sm:inline-block">
                              Surface :{" "}
                            </span>
                            <span>
                              {product.surface} m<sup>2</sup>
                            </span>
                          </span>
                        </li>
                        <li className="inline-flex space-x-3 items-center">
                          <i className="mr-2 text-2xl text-accent">
                            {/* <!-- bx:bath --> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              className="h-5 w-5"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M21 10H7V7c0-1.103.897-2 2-2s2 .897 2 2h2c0-2.206-1.794-4-4-4S5 4.794 5 7v3H3a1 1 0 0 0-1 1v2c0 2.606 1.674 4.823 4 5.65V22h2v-3h8v3h2v-3.35c2.326-.827 4-3.044 4-5.65v-2a1 1 0 0 0-1-1zm-1 3c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4v-1h16v1z"
                              />
                            </svg>
                          </i>
                          <span className="text-md space-x-3">
                            <span className="hidden sm:inline-block">
                              Douche :{" "}
                            </span>
                            <span>{product.douche}</span>
                          </span>
                        </li>
                      </ul>
                      <ul className="flex text-primary justify-center xl:justify-normal space-x-6 w-full flex-wrap">
                        <li className="inline-flex space-x-3 items-center">
                          <i className="mr-2 text-2xl text-accent">
                            {/* <!-- bx:bath --> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              className="h-5 w-5"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M21 10H7V7c0-1.103.897-2 2-2s2 .897 2 2h2c0-2.206-1.794-4-4-4S5 4.794 5 7v3H3a1 1 0 0 0-1 1v2c0 2.606 1.674 4.823 4 5.65V22h2v-3h8v3h2v-3.35c2.326-.827 4-3.044 4-5.65v-2a1 1 0 0 0-1-1zm-1 3c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4v-1h16v1z"
                              />
                            </svg>
                          </i>
                          <span className="text-md space-x-3">
                            <span className="hidden sm:inline-block">
                              Toilletes :{" "}
                            </span>
                            <span>{product.toillete}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="relative mx-auto xl:mx-0 xl:text-right space-y-5 ">
                      <div className="flex gap-2">
                        {product.product_type.name === "vente" ? (
                          <div className="space-y-5">
                            <span className="uppercase text-md font-medium opacity-50">
                              Prix de vente
                            </span>
                            <h2 className="text-2xl font-black text-blue-900">
                              {product.price} DHS
                            </h2>
                          </div>
                        ) : product.product_type.name === "location" ? (
                          <div className="space-y-5">
                            <span className="uppercase text-md font-medium opacity-50">
                              Prix de location
                            </span>
                            <h2 className="text-4xl font-black text-blue-900">
                              {product.price} DHS
                            </h2>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  containerClassName="container-with-dots"
                  customTransition="all .5"
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClassName="product-image-item"
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 3,
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 2,
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1,
                    },
                  }}
                  showDots={false}
                  sliderClassName=""
                  slidesToSlide={1}
                  swipeable
                  className="z-40 flex flex-row gap-2"
                >
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={`https://api.essenlux.ma/storage/${image}`}
                      alt={`Product Image ${index}`}
                      className="w-72 h-[300px] mt-3 object-cover rounded-md"
                    />
                  ))}
                </Carousel>

                <div className="mb-3">
                  <h2 className="text-xl text-left font-black text-blue-900">
                    Description :{" "}
                  </h2>{" "}
                  <p
                    className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg p-2.5"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {product.description}
                  </p>
                </div>

                {product.floor !== null && product.floor !== undefined && (
                  <div className="mb-3">
                    <h3 className="text-xl text-left font-black text-blue-900">
                      Étage du bien:
                    </h3>
                    <p className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg p-2.5">
                      {product.floor}
                    </p>
                  </div>
                )}

                {product.product_etat && (
                  <div className="mb-3">
                    <h3 className="text-xl text-left font-black text-blue-900">
                      Etat du bien:
                    </h3>
                    <p className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg p-2.5 capitalize">
                      {product.product_etat}
                    </p>
                  </div>
                )}

                {product.orientation && (
                  <div className="mb-3">
                    <h3 className="text-xl text-left font-black text-blue-900">
                      Orientation:
                    </h3>
                    <p className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg p-2.5 capitalize">
                      {product.orientation}
                    </p>
                  </div>
                )}

                {product.soil_type && (
                  <div className="mb-3">
                    <h3 className="text-xl text-left font-black text-blue-900">
                      Type du sol:
                    </h3>
                    <p className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg p-2.5 capitalize">
                      {product.soil_type}
                    </p>
                  </div>
                )}

                {/* Display selected features */}
                {JSON.parse(product.main_features) && (
                  <div className="flex flex-wrap space-x-2 items-start flex-col mb-2">
                    <h3 className="text-lg text-left font-black text-blue-900">
                      Caractéristiques générales:{" "}
                    </h3>
                    <div className="flex flex-row flex-wrap gap-4">
                      {JSON.parse(product.main_features).map((featureId) => {
                        const feature = features.find(
                          (f) => f.id === featureId
                        );
                        return (
                          <div
                            key={featureId}
                            className="flex items-center flex-col space-x-1 p-3"
                          >
                            {feature && feature.icon}
                            <span>{feature && feature.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* Display selected features */}
                {JSON.parse(product.interior) && (
                  <div className="flex flex-wrap space-x-2 items-start flex-col mb-2">
                    <h3 className="text-lg text-left font-black text-blue-900">
                      Intérieur:{" "}
                    </h3>
                    <div className="flex flex-row flex-wrap gap-4">
                      {JSON.parse(product.interior).map((interiorId) => {
                        const interior = interiors.find(
                          (f) => f.id === interiorId
                        );
                        return (
                          <div
                            key={interiorId}
                            className="flex items-center flex-col space-x-1 p-3"
                          >
                            {interior && interior.icon}
                            <span>{interior && interior.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* Display selected options */}
                {JSON.parse(product.additional_options) && (
                  <div className="flex flex-wrap space-x-2 items-start flex-col mb-2">
                    <h3 className="text-lg text-left font-black text-blue-900">
                      Options supplémentaires:{" "}
                    </h3>
                    <div className="flex flex-row flex-wrap gap-4">
                      {JSON.parse(product.additional_options).map(
                        (optionId) => {
                          const option = options.find((o) => o.id === optionId);
                          return (
                            <div
                              key={optionId}
                              className="flex items-center space-x-1 p-3 flex-col"
                            >
                              {option && option.icon}
                              <span>{option && option.label}</span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-8">
              <button
                type="submit"
                onClick={toggleFormEdit}
                className="bg-green-100 text-green-800 focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {showFormEdit ? "Close Form" : " Modifier"}
              </button>
              {showFormEdit && (
                <div
                  id="authentication-modal"
                  tabIndex="-1"
                  aria-hidden="true"
                  className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75"
                >
                  <div className="relative p-4 w-full max-w-3xl">
                    <div className="relative bg-white rounded-lg shadow">
                      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-[#CBA660]">
                          Modification de Produit
                        </h3>
                        <button
                          type="button"
                          className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                          onClick={toggleFormEdit}
                        >
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      <div className="p-4 md:p-5 overflow-y-auto max-h-[80vh] z-50">
                        <form onSubmit={handleSubmit(handleUpdate)}>
                          <div className="p-4 md:p-5 grid grid-cols-2 gap-4">
                            {/* Première colonne */}
                            <div className="col-span-2">
                              <label className="block mb-2 text-sm font-medium text-gray-900">
                                Images existantes
                              </label>
                              <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                containerClassName="container-with-dots"
                                customTransition="all .5"
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClassName="product-image-item"
                                keyBoardControl
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                  desktop: {
                                    breakpoint: { max: 3000, min: 1024 },
                                    items: 5,
                                  },
                                  tablet: {
                                    breakpoint: { max: 1024, min: 464 },
                                    items: 3,
                                  },
                                  mobile: {
                                    breakpoint: { max: 464, min: 0 },
                                    items: 1,
                                  },
                                }}
                                showDots={false}
                                sliderClassName=""
                                slidesToSlide={1}
                                swipeable
                                className="flex flex-row gap-2"
                              >
                                {existingImages.map((image, index) => (
                                  <div key={index} className="relative">
                                    <img
                                      src={`https://api.essenlux.ma/storage/${image}`} // Replace with your image URL
                                      alt={`Image ${index + 1}`}
                                      className="w-32 h-32 object-cover rounded-md"
                                    />
                                    <button
                                      type="button"
                                      onClick={() => removeExistingImage(index)}
                                      className="absolute top-1 right-1 bg-white bg-opacity-50 text-gray-600 hover:bg-gray-200 rounded-full p-1"
                                    >
                                      <svg
                                        className="w-4 h-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M6 18L18 6M6 6l12 12"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                ))}
                              </Carousel>
                            </div>
                            <div>
                              <label
                                htmlFor="Name"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                {...register("name")}
                                placeholder="Name"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="surface"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                surface
                              </label>
                              <input
                                type="number"
                                name="surface"
                                id="surface"
                                {...register("surface")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                placeholder="surface"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="price"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                prix
                              </label>
                              <input
                                type="number"
                                name=""
                                id="price"
                                {...register("price")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                placeholder="price"
                                required
                              />
                            </div>
                            {/* Deuxième colonne */}
                            <div>
                              <label
                                htmlFor="images"
                                className="text-sm font-medium text-gray-900"
                              >
                                images
                              </label>
                              <input
                                type="file"
                                name="images"
                                id="images"
                                accept="image/png, image/jpg, image/jpeg"
                                onInput={handleFileChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5  items-center"
                                placeholder="images"
                                multiple
                                required={existingImages ? false : true}
                              />
                              {isLoading && (
                                <p className="text-blue-600">
                                  Téléchargement d'images...
                                </p>
                              )}
                              {uploadSuccess && (
                                <p className="text-green-600">
                                  Images téléchargées avec succès !
                                </p>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor="lien_video"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                lien vidéo
                              </label>
                              <input
                                type="text"
                                name="lien_video"
                                id="lien_video"
                                {...register("lien_video")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5  items-center"
                                placeholder="entrer le lien içi..."
                                multiple
                                required
                              />
                            </div>
                            {/* ************************************************ */}
                            <div>
                              <label
                                htmlFor="status"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                status
                              </label>
                              <select
                                name="status"
                                id="status"
                                {...register("status")}
                                className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                required
                              >
                                <option value="" disabled selected hidden>
                                  status
                                </option>
                                <option value="vendu">vendu</option>
                                <option value="loué">loué</option>
                                <option value="disponible">disponible</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="douche"
                                className="text-sm font-medium text-gray-900 "
                              >
                                Salon
                              </label>
                              <input
                                type="text"
                                name="douche"
                                id="douche"
                                {...register("douche")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                placeholder="entrer ici"
                                multiple
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="toillete"
                                className="text-sm font-medium text-gray-900 "
                              >
                                Salle de bain
                              </label>
                              <input
                                type="text"
                                name="toillete"
                                id="toillete"
                                {...register("toillete")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 items-center"
                                placeholder="entrer ici"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="etat"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Type de Produit
                              </label>
                              <select
                                name="product_type_id"
                                id="type"
                                className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                value={selectedType}
                                {...register("product_type_id")}
                                onChange={handleTypeChange}
                                required
                              >
                                <option value="" disabled selected>
                                  Sélectionner type de produit
                                </option>
                                {productTypes?.map((type) => (
                                  <option
                                    key={type.id}
                                    value={type.id}
                                    onChange={(e) =>
                                      setSubTypes(e.target.value)
                                    }
                                  >
                                    {type.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="subType"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Sous-type de produit
                              </label>
                              <select
                                id="subType"
                                name="sub_type_id"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                required
                                {...register("sub_type_id")}
                              >
                                <option value="">Select sub-type</option>
                                {subTypes.map((subType) => (
                                  <option key={subType.id} value={subType.id}>
                                    {subType.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="ville"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                ville
                              </label>
                              <input
                                type="text"
                                name="ville"
                                id="ville"
                                {...register("ville")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                placeholder="ville"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="address"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                address
                              </label>
                              <input
                                type="text"
                                name="address"
                                id="address"
                                {...register("address")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                placeholder="address"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="rooms_number"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Nombre de chambres
                              </label>
                              <input
                                type="number"
                                name="rooms_number"
                                id="rooms_number"
                                {...register("rooms_number")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                placeholder="nombre de chambres"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                description
                              </label>
                              <textarea
                                type="text"
                                name="description"
                                id="description"
                                {...register("description")}
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5 "
                                placeholder="description"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Étage du bien
                              </label>
                              <input
                                type="number"
                                name="floor"
                                id="floor"
                                {...register("floor")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                                placeholder="Étage"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="soil_type"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Type du sol
                              </label>
                              <select
                                name="soil_type"
                                id="soil_type"
                                {...register("soil_type")}
                                className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                              >
                                <option value="">Sélectionnez</option>
                                <option value="parquet">Parquet</option>
                                <option value="marbre">Marbre</option>
                                <option value="carrelage">Carrelage</option>
                                <option value="autre">Autre</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="product_etat"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Etat du bien
                              </label>
                              <select
                                name="product_etat"
                                id="product_etat"
                                {...register("product_etat")}
                                className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                              >
                                <option value="">Sélectionnez</option>
                                <option value="moins d'un an">
                                  Moins d'un an
                                </option>
                                <option value="d'un an à 5 ans">
                                  D'un an à 5 ans
                                </option>
                                <option value="de 5 ans à 10 ans">
                                  De 5 ans à 10 ans
                                </option>
                                <option value="de 10 ans à 20 ans">
                                  De 10 ans à 20 ans
                                </option>
                                <option value="de 20 ans à 30 ans">
                                  De 20 ans à 30 ans
                                </option>
                                <option value="de 30 ans à 50 ans">
                                  De 30 ans à 50 ans
                                </option>
                                <option value="de 50 ans à 70 ans">
                                  De 50 ans à 70 ans
                                </option>
                                <option value="de 70 ans à 100 ans">
                                  De 70 ans à 100 ans
                                </option>
                                <option value="plus de 100 ans">
                                  Plus de 100 ans
                                </option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="orientation"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Orientation
                              </label>
                              <select
                                name="orientation"
                                id="orientation"
                                {...register("orientation")}
                                className="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#969393] focus:border-[#969393] block w-full p-2.5"
                              >
                                <option value="">Sélectionnez</option>
                                <option value="nord">Nord</option>
                                <option value="ouest">Ouest</option>
                                <option value="est">Est</option>
                                <option value="sud">Sud</option>
                              </select>
                            </div>
                            <div className="col-span-2 mt-4">
                              <label
                                htmlFor="main_features"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Caractéristiques générales:
                              </label>
                              <div className="mt-2 flex flex-row gap-6 flex-wrap justify-start items-start">
                                {features.map((feature) => (
                                  <div key={feature.id} className="">
                                    <a
                                      id={`${feature.id}Link`}
                                      onClick={() => selectFeature(feature.id)}
                                      className={`cursor-pointer items-center flex flex-col justify-center p-1 ${
                                        (selectedFeatures || []).includes(
                                          feature.id
                                        )
                                          ? "text-[#CBA660]"
                                          : "text-gray-500"
                                      }`}
                                    >
                                      <div className="characIcon">
                                        {feature.icon}
                                      </div>
                                      <span className="characIconText centered">
                                        {feature.label}
                                      </span>
                                    </a>
                                    <input
                                      id={feature.id}
                                      type="hidden"
                                      name={feature.id}
                                      value={(selectedFeatures || []).includes(
                                        feature.id
                                      )}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-span-2 mt-4">
                              <label
                                htmlFor="interior"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Intérieur:
                              </label>
                              <div className="mt-2 flex flex-row gap-6 flex-wrap justify-start items-start">
                                {interiors.map((interior) => (
                                  <div
                                    key={interior.id}
                                    className="characLinkBox col-2 centered floatR"
                                  >
                                    <a
                                      id={`${interior.id}Link`}
                                      onClick={() =>
                                        selectInterior(interior.id)
                                      }
                                      className={`cursor-pointer items-center flex flex-col justify-center p-1 ${
                                        (selectedInterior || []).includes(
                                          interior.id
                                        )
                                          ? "text-[#CBA660]"
                                          : "text-gray-500"
                                      }`}
                                    >
                                      <div className="characIcon">
                                        {interior.icon}
                                      </div>
                                      <span className="characIconText centered">
                                        {interior.label}
                                      </span>
                                    </a>
                                    <input
                                      id={interior.id}
                                      type="hidden"
                                      name={interior.id}
                                      value={(selectedInterior || []).includes(
                                        interior.id
                                      )}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-span-2 mt-4">
                              <label
                                htmlFor="additional_options"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                              >
                                Options supplémentaires:
                              </label>
                              <div className="mt-2 flex flex-row gap-6 flex-wrap justify-start items-start">
                                {options.map((feature) => (
                                  <div
                                    key={feature.id}
                                    className="characLinkBox col-2 centered floatR"
                                  >
                                    <a
                                      id={`${feature.id}Link`}
                                      onClick={() =>
                                        selectOption(feature.id, "options")
                                      }
                                      className={`cursor-pointer items-center flex flex-col justify-center p-1 ${
                                        (selectedOptions || []).includes(
                                          feature.id
                                        )
                                          ? "text-[#CBA660]"
                                          : "text-gray-500"
                                      }`}
                                    >
                                      <div className="characIcon">
                                        {feature.icon}
                                      </div>
                                      <span className="characIconText centered">
                                        {feature.label}
                                      </span>
                                    </a>
                                    <input
                                      id={feature.id}
                                      type="hidden"
                                      name={feature.id}
                                      value={(selectedOptions || []).includes(
                                        feature.id
                                      )}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* Add more form fields as needed */}
                          <button
                            type="submit"
                            className="w-auto md:mx-5 mb-4 bg-[#CBA660] text-white hover:bg-[#22213D] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                          >
                            Modifier
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <button
                onClick={() => handleDelete(product.id)}
                type="submit"
                className="mx-2 bg-red-100 text-red-800 focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
