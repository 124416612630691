import axios from "axios";

export const baseURL = "https://api.essenlux.ma/api"

const transport = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    },
});


// Request interceptor to add token to headers
transport.interceptors.request.use(config => {
    const token = sessionStorage.getItem('tokenApi');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response interceptor to handle 401 Unauthorized errors
transport.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Clear token from sessionStorage
            sessionStorage.removeItem('tokenApi');
            sessionStorage.removeItem('isLoggedIn');

            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);
export { transport };