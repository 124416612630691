import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./styles.css";
import "react-multi-carousel/lib/styles.css";
import CarouselDetails from "./CarouselDetails";
import AddCarouselImage from "./AddCarouselImage";
import UpdateCarouselImage from "./UpdateCarouselImage";
import { transport } from "../../utils/TransportApi";

const MySwal = withReactContent(Swal);

const CarouselImages = () => {
  const [images, setImages] = useState([]);
  const [showFormAddImage, setShowFormAddImage] = useState(false);
  const [activeCarouselId, setActiveCarouselId] = useState(null);
  const [imageDetails, setImageDetails] = useState(null);

  const [showFormEditImage, setShowFormEditImage] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleForm = () => {
    setShowFormAddImage(!showFormAddImage);
  };

  const handleUpdateClick = (image) => {
    setSelectedItem(image);
    setShowFormEditImage(true);
  };

  const toggleFormEdit = () => {
    setShowFormEditImage(!showFormEditImage);
  };

  const fetchCarouselImages = () => {
    transport
      .get("/carousel-images")
      .then((response) => setImages(response.data))
      .catch((error) =>
        console.error("Error fetching carousel images:", error)
      );
  };

  const handleDelete = (id) => {
    MySwal.fire({
      html:
        '<div class="flex flex-col items-center justify-center w-full max-w-sm p-4 bg-white rounded-lg">' +
        '<div class="py-2 text-center">' +
        '<p class="text-sm text-gray-600 ml-16">Voulez-vous vraiment supprimer cette image?</p>' +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      focusConfirm: false,
      icon: "warning",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        transport
          .delete(`/upload-image/${id}`)
          .then((response) => {
            if (response.status === 200) {
              setImages(images.filter((image) => image.id !== id));
              Swal.fire({
                text: `Image supprimée avec succès`,
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "custom-confirm-button",
                  cancelButton: "custom-cancel-button",
                },
              });
            }
          })
          .catch((error) => {
            console.error("Erreur lors de la suppression de l'image:", error);
            Swal.fire({
              text: `Une erreur s'est produite`,
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "Réessayer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
              },
            });
          });
      }
    });
  };

  const handleOpenModal = (id) => {
    const selectedImage = images.find((image) => image.id === id);
    setImageDetails(selectedImage);
    setActiveCarouselId(id);
  };

  const handleCloseModal = () => {
    setActiveCarouselId(null);
  };

  // update status
  const handleStatusChange = (id, newStatus) => {
    transport
      .post(`/update-status/${id}`, {
        status: newStatus,
      })
      .then((response) => {
        if (response.status === 200) {
          setImages((prevImages) =>
            prevImages.map((image) =>
              image.id === id ? { ...image, status: newStatus } : image
            )
          );
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur s'est produite lors de la mise à jour du statut de l'image",
        });
      });
  };

  useEffect(() => {
    fetchCarouselImages();
  }, []);

  return (
    <div className="content">
      <div className="w-full h-screen">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-mb-2 py-4 flex flex-wrap flex-grow justify-between">
              <div className="flex items-center py-2">
                <button
                  className="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-semibold rounded-md bg-[#CBA660] text-white hover:bg-[#CBA660] hover:text-[#22213D] focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={toggleForm}
                >
                  {showFormAddImage ? "Close Form" : "Nouvelle image"}
                </button>
                {showFormAddImage && (
                  <AddCarouselImage toggleForm={toggleForm} />
                )}
              </div>
            </div>
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                <table className="w-full">
                  {/* <!-- HEAD start --> */}
                  <thead>
                    <tr className="bg-[#22213D] text-[#CBA660] border-b-8 border-white text-sm leading-4 tracking-wider capitalize">
                      <th className="px-6 py-5 ml-6 font-bold text-left">
                        Title
                      </th>
                      <th className="px-6 py-5 ml-6 font-bold text-left">
                        Description
                      </th>
                      <th className="px-6 py-5 ml-6 font-bold text-left">
                        Status
                      </th>
                      <th className="px-6 py-5 ml-6 font-bold text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {/* <!-- HEAD end --> */}
                  {/* <!-- BODY start --> */}
                  <tbody className="bg-gray-50">
                    {images && images.length > 0 ? (
                      images.map((image) => (
                        <tr key={image.id}>
                          <td className="px-6 py-4 border-b border-gray-200 text-sm">
                            <span>{image.title_fr}</span>
                          </td>
                          <td className="px-6 py-4 border-b border-gray-200 text-start">
                            <span>
                              {image.description_fr.slice(0, 50)}
                              {image.description_fr.length > 50 ? "..." : ""}
                            </span>
                          </td>
                          <td className="px-6 py-4 border-b border-gray-200  text-start">
                            <label className="inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={image.status}
                                onChange={() =>
                                  handleStatusChange(image.id, !image.status)
                                }
                              />
                              <div
                                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer ${
                                  image.status
                                    ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full"
                                    : ""
                                } after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${
                                  image.status
                                    ? "peer-checked:bg-green-500"
                                    : "peer-checked:bg-red-500"
                                }`}
                              ></div>
                            </label>
                          </td>
                          <td className="px-6 py-4 flex justify-center items-center whitespace-no-wrap border-b border-gray-200 capitalize">
                            <button
                              onClick={() => handleOpenModal(image.id)}
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-sky-100 text-sky-800"
                            >
                              <svg
                                className="w-4 h-4 text-sky-600"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                              </svg>
                            </button>
                            <button
                              onClick={() => handleUpdateClick(image)}
                              className="p-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800"
                            >
                              {showFormEditImage &&
                              selectedItem &&
                              selectedItem.id === image.id ? (
                                "Close Form"
                              ) : (
                                <svg
                                  className="w-4 h-4 text-green-800"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </button>{" "}
                            {showFormEditImage &&
                              selectedItem &&
                              selectedItem.id === image.id && (
                                <UpdateCarouselImage
                                  selectedItem={selectedItem}
                                  toggleFormEdit={toggleFormEdit}
                                />
                              )}
                            <button
                              onClick={() => handleDelete(image.id)}
                              className="mx-2 p-2 inline-flex text-xs leading-5 font-semibold rounded-md  bg-red-100 text-red-800"
                            >
                              <svg
                                className="w-4 h-4 text-red-800"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {activeCarouselId && (
                              <CarouselDetails
                                carouselImage={imageDetails}
                                onClose={handleCloseModal}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div className="mx-auto my-10 max-w-2xl text-center sm:my-24">
                            <h2 className="mt-4 text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                              Aucune donnée disponible pour le moment{" "}
                            </h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselImages;
