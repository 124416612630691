import React from "react";
import { Link, useLocation } from "react-router-dom"; // Importez Link et useLocation depuis react-router-dom
import logo from "../images/bg.png";
import "../styles/sidebar.css";
import { toast } from "react-toastify";
import { transport } from "../utils/TransportApi";
import { useAuth } from "../utils/context";

export const Sidebar = () => {
  const location = useLocation();
  const { logout, isLoggedIn, token } = useAuth();

  const logoutAdmin = async () => {
    if (isLoggedIn) {
      try {
        await transport.post("/logout", null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        logout();
        toast.success("Logged out successfully!");
      } catch (error) {
        toast.error("Error logging out");
      }
    } else {
      // Handle the case where the user is not authenticated
      toast.error("User is not logged in");
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="menu">
      <div className="logo">
        <img src={logo} alt="logo" className="h-20 w-20" />
        <h2 className="capitalize text-xl font-bold text-[#CBA660]">
          essen lux
        </h2>
      </div>

      <div className="menu--list">
        <Link
          to="/dashboard"
          className={`item text-semibold rounded-md p-2 ${
            isActive("/dashboard")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6 dark:text-white logo-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
              clipRule="evenodd"
            />
          </svg>
          Dashboard
        </Link>
        <Link
          to="/types"
          className={`item text-semibold rounded-md p-2 ${
            isActive("/types")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6 dark:text-white logo-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M4 4h2v2H4V4zM4 10h2v2H4v-2zM4 16h2v2H4v-2zM10 4h10v2H10V4zM10 10h10v2H10v-2zM10 16h10v2H10v-2z" />
          </svg>
          Types
        </Link>
        <Link
          to="/products"
          className={`item text-semibold ${
            isActive("/products")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6 text-white logo-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M4.857 3A1.857 1.857 0 0 0 3 4.857v4.286C3 10.169 3.831 11 4.857 11h4.286A1.857 1.857 0 0 0 11 9.143V4.857A1.857 1.857 0 0 0 9.143 3H4.857Zm10 0A1.857 1.857 0 0 0 13 4.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 21 9.143V4.857A1.857 1.857 0 0 0 19.143 3h-4.286Zm-10 10A1.857 1.857 0 0 0 3 14.857v4.286C3 20.169 3.831 21 4.857 21h4.286A1.857 1.857 0 0 0 11 19.143v-4.286A1.857 1.857 0 0 0 9.143 13H4.857Zm10 0A1.857 1.857 0 0 0 13 14.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 21 19.143v-4.286A1.857 1.857 0 0 0 19.143 13h-4.286Z"
              clipRule="evenodd"
            />
          </svg>
          Produits
        </Link>
        <Link
          to="/services"
          className={`item ${
            isActive("/services")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            class="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            />
          </svg>
          Services
        </Link>
        <Link
          to="/partners"
          className={`item ${
            isActive("/partners")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6  dark:text-white"
            width="16"
            height="16"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.4l1.4.7a7.7 7.7 0 0 0 .7.3 21 21 0 0 0 16.4-.3l1.5-.7V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5c0-.6-.4-1-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.4 7.9.6-.3V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.4l.6.3a10 10 0 0 0 .7.3 23 23 0 0 0 18-.3h.1L21 13l.4.9ZM12 10a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z"
              clipRule="evenodd"
            />
          </svg>
          Partenaires
        </Link>
        <Link
          to="/headerImages"
          className={`item ${
            isActive("/headerImages")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
              clipRule="evenodd"
            />
            <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
          </svg>
          Images Carousel
        </Link>
        <Link
          to="/team"
          className={`item ${
            isActive("/team")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
              clipRule="evenodd"
            />
          </svg>
          Équipe
        </Link>
        <Link
          to="/testimonials"
          className={`item ${
            isActive("/testimonials")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          <svg
            className="w-6 h-6 text-white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 5C3 3.34315 4.34315 2 6 2H18C19.6569 2 21 3.34315 21 5V15C21 16.6569 19.6569 18 18 18H9.82843L4.70711 23.1213C4.07714 23.7513 3 23.3075 3 22.4142V5ZM6 4C5.44772 4 5 4.44772 5 5V19.5858L8.58579 16H18C18.5523 16 19 15.5523 19 15V5C19 4.44772 18.5523 4 18 4H6ZM7 7H13C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9H7C6.44772 9 6 8.55228 6 8C6 7.44772 6.44772 7 7 7ZM7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11Z"
              fill="currentColor"
            />
          </svg>
          Témoignages
        </Link>
        <Link
          to="/contacts"
          className={`item ${
            isActive("/contacts")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          {" "}
          {/* Remplacez <a> par <Link> */}
          <svg
            className="w-6 h-6  dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M4 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h1v2a1 1 0 0 0 1.707.707L9.414 13H15a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4Z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M8.023 17.215c.033-.03.066-.062.098-.094L10.243 15H15a3 3 0 0 0 3-3V8h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-1v2a1 1 0 0 1-1.707.707L14.586 18H9a1 1 0 0 1-.977-.785Z"
              clipRule="evenodd"
            />
          </svg>
          Contacts
        </Link>
        <Link
          to="/pack-services"
          className={`item ${
            isActive("/pack-services")
              ? "bg-[#CBA660] text-[#fff]"
              : "text-white hover:text-[#CBA660]"
          }`}
        >
          {" "}
          {/* Remplacez <a> par <Link> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6  dark:text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 20a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H7zm-1-9h14"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 16h.01M15 16h.01"
            ></path>
          </svg>
          Pack Services
        </Link>
        <Link to="/" className="item text-white" onClick={logoutAdmin}>
          <svg
            className="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"
            />
          </svg>
          Déconnecter
        </Link>
      </div>
    </div>
  );
};
